<template>
  <Form @submit="onSubmit" :validation-schema="schema" class="form-contact" ref="contactForm">

    <div class="form__row">
      <div class="form__col">
        <label class="form__label" for="fullname"> Nombre Completo</label>
        <Field name="fullname" type="text" placeholder="Nombre Completo" class="form__input" />
        <ErrorMessage name="fullname" as="span" class="form__error" />
      </div>
    </div>
    <div class="form__row">
      <div class="form__col">
        <label class="form__label" for="email"> Correo electrónico </label>
        <Field class="form__input" type="email" name="email" placeholder="Correo electrónico" />
        <ErrorMessage name="email" as="span" class="form__error" />
      </div>
    </div>
    <div class="form__row">
      <div class="form__col">
        <label class="form__label" for="subject">
          Asunto
        </label>
        <Field as="select" name="subject" id="subject" class="form__input">
          <option value="" disabled selected>Selecciona una opción</option>
          <option value="Cliente / Servicio ">Cliente / Servicio </option>
          <option value="¡Quiero trabajar con ustedes!">¡Quiero trabajar con ustedes!</option>
          <option value="Press">Press</option>
          <option value="otro">Otro</option>
        </Field>
        <ErrorMessage name="subject" as="span" class="form__error" />
      </div>
    </div>

    <div class="form__row form__row--mMargin">
      <div class="form__col">
        <label class="form__label" for="message">
          Mensaje
        </label>
        <Field as="textarea" placeholder="¿Cómo te podemos ayudar?" name="message" class="form__textArea" rows="10" />
        <ErrorMessage name="message" as="span" class="form__error" />
      </div>
    </div>
    <div class="form__row">
      <InputFile v-model="fileToUpload" />
    </div>
    <div class="form__row">
      <div class="form__col">
        <div class="form__radio">
          <label for="politics">
            Acepto <a :href="policiesFile" target="_blank">la
              política de privacidad, tratamiento y protección de datos personales</a>
          </label>
          <Field class="form__radio__input" type="checkbox" name="politics" id="politics" :value="true" />
          <span class="form__radio__checkmark"></span>
          <ErrorMessage name="politics" as="span" class="form__error" />

        </div>
      </div>
    </div>
    <div class="form__row">
      <div class="form__col">
        <Field name="recaptcha" class="form__input" v-model="recaptchaValue">
          <vue-recaptcha :sitekey="recaptchaKey" size="normal" theme="light" @verify="recaptchaVerified"
            @expire="recaptchaExpired" @fail="recaptchaFailed" ref="recaptchaComponent">
          </vue-recaptcha>
          <ErrorMessage name="recaptcha" as="span" class="form__error" />
        </Field>
      </div>
    </div>

    <div class="form__row">
      <div class="form__col">
        <button class="btn btn--black ttU" :disabled="isSending">
          {{ isSending ? 'Enviando información ' : 'enviar información' }}
          <svg v-if="isSending" xmlns="http://www.w3.org/2000/svg" class="rotate-center " width="24" height="24"
            viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round"
            stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M12 3a9 9 0 1 0 9 9" />
          </svg>
        </button>
      </div>
    </div>
  </Form>
</template>
<script>
import { ref } from 'vue';
import { Form, Field, useForm, ErrorMessage, useField } from 'vee-validate';
import * as yup from 'yup';
import axios from 'axios';
import vueRecaptcha from 'vue3-recaptcha2';
import SETTINGS from '@/partials/settings.js'
import InputFile from './InputFile.vue';

export default {
  emits: ['onModalSent'],
  components: {
    Form,
    Field,
    ErrorMessage,
    vueRecaptcha,
    InputFile
  },

  setup(props, { emit }) {
    const policiesFile = ref('')
    const recaptchaKey = ref('')
    const recaptchaComponent = ref(null)
    recaptchaKey.value = SETTINGS.VUE_APP_RECAPTCHA_KEY
    const fileToUpload = ref(null)
    const schema = yup.object({
      fullname: yup.string().required('Campo obligatorio').min(5, 'El nombre debe tener al menos 5 caracteres').matches(/^[a-zA-ZáéíóúñÁÉÍÓÚÑ\s]*$/, 'Ingresa un nombre válido'),
      email: yup.string().required('Campo obligatorio').email('El correo no es válido'),
      subject: yup.string().required('Campo obligatorio'),
      message: yup.string().required('Campo obligatorio').min(30, 'El mensaje debe tener al menos 30 caracteres'),
      politics: yup.boolean().required('Campo obligatorio'),
      recaptcha: yup.string().required('Confirma que eres una persona'),
    });

    const { value: recaptchaValue, errorMessage: recaptchaError } = useField('recaptcha')
    const successMessage = { title: 'Recibimos tus datos', content: 'En breve nos comunicaremos contigo.', button: 'Continuar' }
    const errorMessage = { title: 'Ups, algo salió mal ', content: 'Ingresa tu información nuevamente ', button: 'Volver a intentar ' }
    const isSending = ref(false)

    const onSubmit = async (values, actions) => {
      isSending.value = true
      const formData = new FormData();
      formData.append('subject', values.subject + " - " + values.fullname);
      formData.append('message', `${values.email} - ${values.message}`);
      formData.append('from_email', "info@sanchobbdo.com");
      formData.append('to_email', "comunicaciones@sanchobbdo.com");
      formData.append('cc_email', [values.email])
      formData.append('smtp_server', "smtp.oneomnicom.com");
      formData.append('smtp_port', 2525);
      formData.append('smtp_username', "bbdo-co-landing-global");
      formData.append('smtp_password', "e1E7bj5CHcvdnsUW");

      if (fileToUpload.value) {
        formData.append('file', fileToUpload.value);
      }

      try {
        await axios.post('https://smtp-api.quadi.io/send_email/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        emit('onModalSent', successMessage, 'success');
        actions.resetForm();
        fileToUpload.value = null
        isSending.value = false
      } catch (error) {
        emit('onModalSent', errorMessage, 'error')
        console.error(error);
        isSending.value = false
      }
    }

    //get policies url file from wordpress
    const getPolicies = async () => {
      axios.get("/pages/184").then((result) => {
        const { policies_group } = result.data.acf
        const { policies_file } = policies_group
        policiesFile.value = policies_file
      });
    }
    getPolicies()

    const recaptchaVerified = (response) => {
      recaptchaValue.value = response;
    }
    const recaptchaExpired = () => {
      recaptchaComponent.value.reset()
      recaptchaValue.value = null
    }
    const recaptchaFailed = () => {
      recaptchaValue.value = null
    }


    return {
      onSubmit,
      schema,
      policiesFile,
      recaptchaKey,
      recaptchaValue,
      recaptchaVerified,
      recaptchaExpired,
      recaptchaFailed,
      recaptchaComponent,
      fileToUpload,
      isSending
    };
  },



};
</script>
