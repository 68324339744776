<template>
  <section class="thatIsHowItWorks__gallery" data-section="dark">
    <swiper :autoHeight="true" :spaceBetween="45" class="thatIsHowItWorks__gallery__carousel" :slidesPerView="'1.5'"
      v-if="images">
      <swiper-slide v-for="(img, index) in images" :key="index">
        <img :src="img" alt="galery" />
      </swiper-slide>

    </swiper>
  </section>
</template>
<script>
import { ref } from "vue";
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper Styles
import "swiper/swiper.scss";

export default {
  name: "Gallery",
  props: {
    images: ref(null),
  },
  components: {
    Swiper,
    SwiperSlide,
  },
};
</script>