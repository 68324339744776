<template>
  <section class="ideasInTime">
    <!-- <div class="container container--general">
      <div class="ideasInTime__title">
        <h2 class="subtitles subtitles--medium">Ideas en el tiempo</h2>
        <p>Grandes ideas, aún más grandes historias.</p>
      </div>
    </div> -->
    <!-- <div class="container">
      <div class="ideasInTime__timeline">
        <div class="ideasInTime__timeline__box">
          <div class="ideasInTime__timeline__box__bar"></div>
          <div class="ideasInTime__timeline__entry">
            <h3>1990</h3>
            <img src="@/assets/images/somosSancho/timeline.jpg" alt="" />
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Blandit
              facilisi eget mattis eget consequat eget viverra tempor.
              Consectetur dui nisi, id orci, leo ultrices adipiscing.
            </p>
          </div>
          <div class="ideasInTime__timeline__entry">
            <h3>1990</h3>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Blandit
              facilisi eget mattis eget consequat eget viverra tempor.
              Consectetur dui nisi, id orci, leo ultrices adipiscing.
            </p>
          </div>
          <div class="ideasInTime__timeline__entry">
            <h3>1990</h3>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Blandit
              facilisi eget mattis eget consequat eget viverra tempor.
              Consectetur dui nisi, id orci, leo ultrices adipiscing.
            </p>
          </div>
          <div class="ideasInTime__timeline__entry">
            <h3>1990</h3>
            <img src="@/assets/images/somosSancho/timeline.jpg" alt="" />
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Blandit
              facilisi eget mattis eget consequat eget viverra tempor.
              Consectetur dui nisi, id orci, leo ultrices adipiscing.
            </p>
          </div>
          <div class="ideasInTime__timeline__entry">
            <h3>1990</h3>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Blandit
              facilisi eget mattis eget consequat eget viverra tempor.
              Consectetur dui nisi, id orci, leo ultrices adipiscing.
            </p>
          </div>
        </div>
      </div>
    </div> -->
  </section>
</template>
<script>
export default {
  name: "IdeasInTime",
};
</script>