<template>
    <h1 class="mt60 pl60 taC"> Not found page works!!</h1>
</template>

<script>
export default {
    name: 'PageNotFound'
}
</script>

<style>
</style>