<template>
  <section v-if="result" class="ourWayWork" data-section="dark">
    <!-- custom content with repeater -->

    <div v-for="(way, index) in result.banner_our_way_of_work" :key="index" :id="`section-${index}`" class="boxesHow">
      <div class="ourWayWork__box">
        <div v-if="way.image" class="ourWayWork__image" data-aos="fade-up" data-aos-duration="500" data-aos-delay="300"
          data-aos-easing="ease-in">
          <img :src="way.image" :alt="way.title" />
        </div>
        <div class="ourWayWork__body" data-aos="fade-right" data-aos-duration="700" data-aos-delay="500">
          <h3 class="subtitles subtitles--small">
            {{ way.title }}
          </h3>
          <p>
            {{ way.description }}
          </p>
          <a :href="way.url" class="ttU btn btn--black">{{
          way.button
          }}</a>
        </div>
      </div>
    </div>

    <!-- custom content with repeater -->
  </section>
</template>
<script>
import { ref, onMounted } from "vue";
import useEventBus from "@/composables/useEventBus";

import AOS from "aos";
import "aos/dist/aos.css";

export default {
  name: "OurWayWork",
  props: {
    result: ref(null),
  },
  setup() {
    const { onEvent } = useEventBus();
    onMounted(() => {
      AOS.init();
      let boxes = document.querySelectorAll('.boxesHow')
      onEvent("anchorLink", (name) => {
        boxes.forEach(box => {
          let boxID = box.id
          let offsetTop = box.offsetTop
          if (boxID === name) {
            window.scrollTo({
              top: offsetTop,
              left: 0,
              behavior: 'smooth'
            });
          }
        })
      });
    });


    return {};
  },
};
</script>