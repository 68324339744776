<template>
  <section class="detailHow__content" data-section="dark">
    <div class="container container--general">
      <div class="detailHow__grid">
        <h2 class="subtitles subtitles--small">
          {{contentBlock.short_description}}
        </h2>
        <div class="detailHow__description" v-html="contentBlock.full_description">
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { ref } from '@vue/reactivity'
export default {
  name: 'BlockInformation',
  props: {
    contentBlock: ref({})
  }
}
</script>