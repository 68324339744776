<template>
    <ul v-if="categorieList" class="categoriesBox ">
        <template v-for="(cat) in categorieList" :key="cat">
            <li class="categoriesBox__pills" v-if="cat.name!=='Todos'|| cat.id !==11">
                <router-link
                    :to="{ name: 'Detail Categories', params: { categorie: cat.slug }, query: { id: cat.id } }">
                    {{ cat.name }}
                </router-link>
            </li>

        </template>
    </ul>
</template>

<script>
export default {
    name: 'Categories',
    props: {
        categorieList: {
            default: [],
            required: true,
            type: Array
        }
    }
}
</script>

