<script>
import { useRoute } from "vue-router";
/* Import Components  */
import Footer from "@/components/globals/Footer";
import Header from "@/components/globals/Header";
export default {
  name: "App",
  components: {
    Header,
    Footer,
  },
  setup() {
    const route = useRoute();
    return {
      route,
    };
  },
};
</script>

<template>
  <Header />
  <router-view v-slot="{ Component, route }">
    <keep-alive>
      <component :is="Component" :key="route.name" />
    </keep-alive>
  </router-view>
  <Footer v-if="!route.meta.hideFooter" />
</template>
