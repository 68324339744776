<template>
  <div class="form">
    <div class="form__container">
      <h2>Trabaja con nosotros</h2>
      <form class="form__general" action="">
        <div class="form__row form__row--wCol">
          <div class="form__col">
            <label class="form__label" for="name"> Nombre </label>
            <input class="form__input" type="text" v-model="dataForm.name" name="name" placeholder="Nombre" />
          </div>
          <div class="form__col">
            <label class="form__label" for="lastname"> Apellido </label>
            <input class="form__input" type="text" v-model="dataForm.lastname" name="lastname" placeholder="Apellido" />
          </div>
        </div>
        <div class="form__row">
          <div class="form__col">
            <label class="form__label" for="email"> Correo electrónico </label>
            <input class="form__input" type="email" v-model="dataForm.email" name="email"
              placeholder="Correo electrónico" />
          </div>
        </div>
        <div class="form__row">
          <div class="form__col">
            <label class="form__label" for="linkedinProfile">
              Perfil de Linkedin
            </label>
            <input class="form__input" type="text" v-model="dataForm.linkedinProfile" name="linkedinprofile"
              placeholder="Perfil de Linkedin" />
          </div>
        </div>
        <div class="form__row mb25">
          <div class="form__col">
            <div @dragenter.prevent="setActive" @dragleave.prevent="setInactive" @dragover.prevent="setActive"
              @drop.prevent="dropFile" :class="{ 'active': active }" class="form__dropzone">
              <img src="@/assets/images/generals/upload.svg" alt="" />
              <p>arrastra y suelta tu cv pdf</p>
              <span>{{ dropzoneFile.name }}</span>
              <input class="form__file" type="file" @change="dropFile" name="fileUpload">
            </div>
          </div>
        </div>
        <div class="form__row">
          <div class="form__col">
            <div class="form__radio">
              <label for="politics">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Odio
                gravida lacus tellus dictum ut purus. Fames risus erat cras
                dictum et dignissim id.
              </label>
              <input v-model="dataForm.treatment" class="form__radio__input" type="checkbox" name="politics"
                id="politics">
              <span class="form__radio__checkmark"></span>
            </div>
          </div>
        </div>
        <div class="form__row">
          <div class="form__col">
            <button type="submit" @click.prevent="handleSubmit(dataForm)" class="btn btn--black ttU">enviar mi
              cv</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
export default {
  name: "Form",
  setup() {
    let dropzoneFile = ref("");
    const active = ref(false);
    const dataForm = ref({})

    const dropFile = async (e) => {
      setInactive()
      const fileUploaded = e.dataTransfer?.files[0] || e.target?.files[0]
      dropzoneFile.value = fileUploaded
    };

    const setActive = () => {
      active.value = true;
    };
    const setInactive = () => {
      active.value = false;
    };
    return {
      dropzoneFile,
      active,
      setActive,
      setInactive,
      dropFile,
      dataForm,
      handleSubmit: (dataForm) => {
        let dataSend = {
          ...dataForm, file: dropzoneFile.value
        }
        console.log("llega data form", dataSend);
      }
    };
  },
};
</script>