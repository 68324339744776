<template>
  <LoaderComponent :loading="isLoading" />
  <div class="errorSite" v-if="!workInfo || errorMessage">{{ errorMessage }}</div>
  <template v-else>
    <div class="detailHow" data-scroller>
      <Banner :bannerInfo="bannerInfo" />
      <BlockInformation :contentBlock="contentBlock" />
      <TheWork :dataWork="workInfo" />
    </div>
  </template>
</template>

<script>
import axios from 'axios';
import { ref } from "vue";
import { useRoute } from "vue-router";

// Import Components
import LoaderComponent from "@/components/globals/LoaderComponent";
import Header from "@/components/globals/Header";
import Banner from "@/components/globals/Banner";
import BlockInformation from "@/components/detailTheHow/BlockInformation";
import TheWork from "@/components/globals/TheWork";
export default {
  name: "DetailTheHow",
  components: {
    Header,
    TheWork,
    Banner,
    BlockInformation,
    LoaderComponent
  },
  setup() {
    const route = useRoute();
    const slug = route.params.slug;

    const isLoading = ref(true)
    const errorMessage = ref('')

    const bannerInfo = ref({});
    const contentBlock = ref({});
    const workInfo = ref([]);

    const getDetailInfo = async (slug) => {
      isLoading.value = true
      try {
        const { data } = await axios.get(`/sancho-services?slug=${slug}`);
        const [response] = data
        // Get data for banner 
        bannerInfo.value = {
          title: response.title.rendered,
          img_mobile: response.acf.thumbnail_image,
          img_desktop: response.acf.fullscreen_banner,
        }
        // Get data for detail content
        contentBlock.value = {
          short_description: response.acf.short_description,
          full_description: response.acf.full_description
        }
      } catch (error) {
        errorMessage.value = 'Ocurrió un error al cargar la información'
        isLoading.value = false
      }
    }
    const getCases = async () => {
      const { data } = await axios.get("/sancho-cases");
      workInfo.value = {
        slider: data,
      }
      isLoading.value = false

    }


    getDetailInfo(slug)
    getCases()

    return {
      bannerInfo,
      contentBlock,
      workInfo,
      isLoading,
      errorMessage
    };
  },
};
</script>