import axios from "axios";
import { ref } from "vue";

const useDetailBlog = (blogSlug) => {
  const dataBlogdetail = ref([]);
  const categorieList = ref([]);
  const bannerInfo = ref({});

  const isLoading = ref(false);
  const errorMessage = ref();

  const getBlogDetail = async (slug) => {
    if (!slug) return;
    isLoading.value = true;

    try {
      const { data } = await axios.get(`/posts?slug=${slug}`);
      const [dataResponse] = data;
      const { id } = dataResponse;

      handleBlogContent(dataResponse);
      getPostCategories(id);

      errorMessage.value = null;
    } catch (error) {
      isLoading.value = false;
      errorMessage.value = "No se pudieron cargar los datos";
    }
  };

  // handle blog detail content
  const handleBlogContent = ({ title, acf }) => {
    const { banner_desktop, banner_mobile, ...dataContent } = acf;

    bannerInfo.value = {
      title: title.rendered,
      banner_desktop: acf.banner_desktop,
      banner_mobile: acf.banner_mobile,
    };

    dataBlogdetail.value = dataContent;
  };

  // get the related post categories
  const getPostCategories = async (id) => {
    try {
      const { data } = await axios.get(`/categories?post=${id}`);
      categorieList.value = data;
    } catch (error) {
      categorieList.value = [];
      isLoading.value = false;
    }
    isLoading.value = false;
  };
  getBlogDetail(blogSlug);
  return {
    bannerInfo,
    errorMessage,
    getBlogDetail,
    dataBlogdetail,
    categorieList,
    isLoading,
  };
};

export default useDetailBlog;
