<template>
  <!-- <LoaderPresentation :loading="isLoading" /> -->
  <LoaderComponent :loading="isLoading" />

  <span v-if="isLoading"></span>
  <span class="errorSite" v-else-if="!howSection || errorMessage"> {{ errorMessage }}</span>
  <div class="home" data-scroller v-else>
    <div class="scrollmsg">
      <div class="scrollmsg__text">
        scroll
      </div>
      <svg width="14" height="21" viewBox="0 0 14 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6.9999 20.6L6.9999 0.600006M6.9999 20.6L13.3999 14.2L6.9999 20.6ZM6.9999 20.6L0.599903 14.2L6.9999 20.6Z"
          stroke="white" />
      </svg>
    </div>
    <Video :result="videoSources" />
    <TheWork :dataWork="workInfo" v-if="showBlogCases" />
    <TheWorkTemporal v-else :dataWork="workInfo" />
    <TheHow :dataHow="howSection" />
  </div>
</template>

<script>
import { ref } from "vue";
import axios from "axios";
import Header from "@/components/globals/Header";
import TheWork from "@/components/globals/TheWork";
import TheWorkTemporal from "@/components/globals/TheWorkTemporal";
import TheHow from "@/components/home/TheHow";
import Video from "@/components/home/Video";
import LoaderComponent from "@/components/globals/LoaderComponent";
import LoaderPresentation from "@/components/globals/LoaderPresentation";
export default {
  name: "Home",
  components: {
    LoaderComponent,
    Header,
    TheWork,
    TheWorkTemporal,
    TheHow,
    Video,
    LoaderPresentation,
  },

  setup() {
    const isLoading = ref(true);
    const errorMessage = ref('');
    const dataResults = ref([]);
    const videoSources = ref({});
    const workInfo = ref([]);
    const howSection = ref({});
    const showBlogCases = ref(true);
    const presentation = ref(true);


    const pageRequest = axios.get("/pages/43");
    const casesRequest = axios.get("/sancho-cases");
    const servicesRequest = axios.get("/sancho-services");
    Promise.all([pageRequest, casesRequest, servicesRequest]).then((values) => {
      isLoading.value = true;
      presentation.value = true

      const [dataPage, dataCases, dataServices] = values
      // send params for video information
      getDataVideo(dataPage.data.acf);
      // send params for get data for cases section  
      getFeaturedCases(dataCases.data, dataPage.data.acf)
      // send params for get data for services section 
      getServices(dataServices.data, dataPage.data.acf)
      errorMessage.value = null
    }).catch((error) => {
      console.log(error);
      errorMessage.value = 'Ocurrió un error inesperado'
    }).finally(() => {
      isLoading.value = false;
      presentation.value = false
    });

    // get the video information
    const getDataVideo = ({ load_video, video_desktop, video_mobile, video_desktop_url, video_mobile_url }) => {
      videoSources.value = {
        load_video,
        video_desktop,
        video_mobile,
        video_desktop_url,
        video_mobile_url
      };
    };
    // get featured cases 

    const getFeaturedCases = (cases, { case_title, case_description, ...rest }) => {

      const { hide_blog_cases, cases_list } = rest
      showBlogCases.value = hide_blog_cases

      if (!hide_blog_cases) {
        workInfo.value = {
          slider: cases_list,
          case_title,
          case_description,
        }

        return
      }

      const features = cases
        .filter((item) => {
          if (item.acf.featured_case) {
            return item;
          }
        })
      workInfo.value = {
        slider: features,
        case_title,
        case_description,
      }
    };

    const getServices = (services, { service_title, service_description, results, results_title }) => {
      howSection.value = {
        carousel: services,
        service_title,
        service_description, results, results_title
      }
    };

    return {
      isLoading,
      dataResults,
      videoSources,
      workInfo,
      showBlogCases,
      howSection,
      errorMessage,
      presentation
    };
  },
};
</script>
