<template>
    <LoaderComponent :loading="isLoading" />
    <div class="errorSite" v-if="!bannerPost || !listPosts || errorMessage">{{ errorMessage }}</div>
    <template v-else>
        <Banner class="mb100 mbm60" :dataBanner="bannerPost" />
        <TheMostNew :postsList="listPosts" :postsTitle="blogACF.most_recent_title"
            :categoryTitle="blogACF.find_out_more_title" />
    </template>
</template>

<script>
import LoaderComponent from "@/components/globals/LoaderComponent";
import Banner from "@/components/blog/Banner";
import TheMostNew from "@/components/blog/TheMostNew";

import { useRoute } from "vue-router";
import useBlog from "../composables/blog/useBlog";

export default {
    name: "DetailCategorie",
    components: {
        LoaderComponent,
        Banner,
        TheMostNew
    },
    setup() {
        const route = useRoute()
        const { bannerPost, blogACF, listPosts, getDataPosts, isLoading, errorMessage } = useBlog();
        const getData = async () => {
            await getDataPosts(`/posts?categories=${route.query.id}`);
        };
        getData();
        return {
            bannerPost,
            blogACF,
            listPosts,
            getDataPosts,
            isLoading, errorMessage
        };
    },
};
</script>

