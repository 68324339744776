<template>
  <section class="thatIsHowItWorks__vacancies" data-section="dark">
    <div class="container container--limit container--limit__big">
      <div class="thatIsHowItWorks__vacancies__filters">
        <div class="thatIsHowItWorks__vacancies__filters__box">
          <select @change="selectArea($event)" class="thatIsHowItWorks__vacancies__filters__filter" name="byArea"
            id="byArea">
            <option value="" selected>por área de trabajo</option>
            <option v-for="(area, index) in areas" :key="index" :label="area" :value="area">{{ area }}</option>
          </select>
          <img src="@/assets/images/generals/arrowDown.svg" alt="">
        </div>
        <div class="thatIsHowItWorks__vacancies__filters__box">
          <select @change="selectPosition($event)" class="thatIsHowItWorks__vacancies__filters__filter"
            name="byPosition" id="byPosition">
            <option value="" selected>por cargo</option>
            <option v-for="(position, index) in positions" :key="index" :label="position" :value="position">{{ position
            }}
            </option>
          </select>
          <img src="@/assets/images/generals/arrowDown.svg" alt="">
        </div>
      </div>

      <div class="thatIsHowItWorks__vacancies__container">
        <div :v-if="vacancies.length" v-for="vacancie in vacanciesFilters" :key="vacancie.id"
          class="thatIsHowItWorks__vacancies__vacancie">
          <div class="thatIsHowItWorks__vacancies__vacancie__title">
            <h3>
              {{ vacancie.acf.title }}
            </h3>
            <p>
              {{ vacancie.acf.short_description }}
            </p>
          </div>
          <div class="thatIsHowItWorks__vacancies__vacancie__description">
            <p>
              {{ vacancie.acf.short_description }}
            </p>
          </div>
          <div class="thatIsHowItWorks__vacancies__vacancie__action">
            <a @click="goToDetail(vacancie.slug)">
              <img src="@/assets/images/generals/plus.svg" alt="">
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import axios from "axios";
import { ref, onMounted } from "vue";
import { useRouter } from 'vue-router';
/*  import data */
export default {
  name: "Vacancies",
  setup() {
    const vacancies = ref([])
    const vacanciesFilters = ref([])
    const vacan = ref([])
    const areas = ref([])
    const positions = ref([])

    onMounted(async () => {
      vacan.value = await getVacancies();
      const dataVacancies = await JSON.parse(JSON.stringify(vacan.value.data))
      vacancies.value = await dataVacancies

      vacanciesFilters.value = await vacancies.value
      getAreas();
      getPosition();
    })
    const getVacancies = async () => {
      return await axios.get("/jobs")
    }
    const getAreas = () => {
      let vacArr = []
      vacancies.value.forEach(vacancie => {
        if (!vacArr.includes(vacancie.acf.area)) {
          vacArr.push(vacancie.acf.area)
        }
      })
      areas.value = vacArr
    }
    const getPosition = () => {
      let vacArr = []
      vacancies.value.forEach(vacancie => {
        if (!vacArr.includes(vacancie.acf.position)) {
          vacArr.push(vacancie.acf.position)
        }
      })
      positions.value = vacArr
    }
    const selectArea = (event) => {
      let areaSelected = event.target.value;
      filterArea(areaSelected)
    }
    const selectPosition = (event) => {
      let positionSelected = event.target.value;
      filterPosition(positionSelected)
    }
    const filterArea = (area) => {
      vacanciesFilters.value = vacancies.value.filter((vacancie) => {
        if (area === vacancie.acf.area) return vacancie;
      });
    }
    const filterPosition = (position) => {
      vacanciesFilters.value = vacancies.value.filter((vacancie) => {
        if (position === vacancie.acf.position) return vacancie;
      });
    }
    const router = useRouter()

    const goToDetail = (slug) => {
      router.push({
        name: 'DetailJob',
        params: {
          slug,
        },
      });
    }

    return { vacancies, areas, positions, selectArea, vacanciesFilters, selectPosition, goToDetail }
  }
};
</script>