<template>
  <LoaderComponent :loading="isLoading" />
  <span v-if="isLoading"></span>
  <span class="errorSite" v-else-if="!pageContent || errorMessage"> {{ errorMessage }}</span>
  <!-- PAGE CONTENT  -->
  <div class="detailTheWork horizontalScroll" v-else>

    <!-- FIRST SECTION IS NOT IN LOOP -->
    <section class="page" data-section="light">
      <!-- FIRST SECTION CONTENT -->
      <Banner :bannerInfo="bannerInfo" :categoriePills="categoriePills" />
      <!-- FIRST SECTION CONTENT -->
    </section>
    <!-- FIRST SECTION IS NOT IN LOOP -->

    <!-- SECTIONS TO LOOP -->
    <template v-if="sectionsPage.work_custom_layout">
      <section class="page" :data-section="layout.text_image_content ? 'dark' : 'light'"
        v-for="(layout, idx) in sectionsPage.work_custom_layout" :key="idx">
        <template v-if="layout.text_image_content">
          <div :class="[
            layout.show_vertical
              ? 'detailBox-grid-vertical'
              : 'detailBox-grid-double',
          ]">
            <div class="detailBox-text" v-html="layout.text_image_content.content_description">
            </div>
            <div class="detailBox-image">
              <img :src="layout.text_image_content.image_description" class="image-cover" alt="image content" />
            </div>
          </div>
        </template>
        <template v-else>
          <Video :data="layout.load_video ? layout.video_link : layout.video_media" />
        </template>
      </section>
    </template>
    <!-- SECTIONS TO LOOP -->
    <!-- LAST SECTION IS NOT IN LOOP -->
    <section class="page">
      <TheWork :dataWork="workInfo" />
    </section>
    <!-- LAST SECTION IS NOT IN LOOP -->
  </div>
  <!-- PAGE CONTENT  -->
</template>
<script>
import { ref, watch, onUpdated } from "vue";
import axios from "axios";
import { useRoute } from "vue-router";
import { handleScrollHorizontal } from "@/helpers/scrollHorizontal";

/* Import Components */

import LoaderComponent from "@/components/globals/LoaderComponent";
import Header from "@/components/globals/Header";
import Banner from "@/components/detailTheWork/Banner";
import TheWork from "@/components/globals/TheWork";
import Video from "@/components/detailTheWork/Video";

import { headerChange } from "@/partials/headerChange";

/* Import Libraries */

export default {
  name: "DetailTheWork",
  components: {
    LoaderComponent,
    Header,
    Banner,
    TheWork,
    Video,
  },
  setup() {
    const route = useRoute();

    const isLoading = ref(true);
    const errorMessage = ref("");

    const isDesktop = ref(null);
    const pageContent = ref([]);
    const sectionsPage = ref([]);
    const bannerInfo = ref([]);

    const workInfo = ref([]);

    const categoriePills = ref([]);

    // handle requests

    const getcaseInformation = (slug) => {
      if (!slug) return;
      const currentCase = axios.get(`/sancho-cases?slug=${slug}`);
      const getCases = axios.get("/sancho-cases");
      const getCategories = axios.get("/categories");
      isLoading.value = true;

      Promise.all([currentCase, getCases, getCategories])
        .then((value) => {
          const [dataDetail, casesList, categoriesList] = value;
          const [detailContent] = dataDetail.data;
          pageContent.value = detailContent;
          sectionsPage.value = detailContent.acf;
          const { banner_desktop, banner_mobile, client_name, work_date } =
            sectionsPage.value;

          handleCategories(pageContent.value, categoriesList.data);

          bannerInfo.value = {
            title: pageContent.value.title.rendered,
            banner_desktop,
            banner_mobile,
            client_name,
            work_date,
          };
          workInfo.value = {
            slider: casesList.data,
          };
        })
        .catch((error) => {
          console.log("error", error);
          errorMessage.value = "Ocurrió un error ";
        })
        .finally(() => {
          isLoading.value = false;
          errorMessage.value = null;
        });
    };
    watch(
      () => route.params.slug,
      () => getcaseInformation(route.params.slug)
    );
    getcaseInformation(route.params.slug);

    const handleCategories = ({ categories }, cats) => {
      let categoryName = [];
      cats.filter((item) => {
        categories.map((cat) => {
          if (item.id === cat) {
            categoryName.push(item.name);
            return categoryName;
          }
        });
      });
      categoriePills.value = categoryName;
    };

    onUpdated(() => {
      handleScrollHorizontal();
      headerChange();
    });

    return {
      isDesktop,
      bannerInfo,
      pageContent,
      sectionsPage,
      categoriePills,
      isLoading,
      errorMessage,
      workInfo,
      getcaseInformation,
      handleCategories,
    };
  },
};
</script>
