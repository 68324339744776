<template>
  <section class="ourPartners" data-section="dark" v-if="partnersInfo">
    <div class="container container--custom">
      <div class="ourPartners__title">
        <h2 class="subtitles subtitles--medium">{{ partnersInfo.our_partners_title }}</h2>
        <p>
          {{ partnersInfo.our_partners_subtitle }}
        </p>
      </div>
      <div class="ourPartners__carousel">
        <swiper :spaceBetween="49" :slidesPerView="3" :speed="1200" :autoplay="{
          delay: 2000,
          disableOnInteraction: false,
        }" :loop="true" class="ourPartners__carousel__main" :breakpoints="{
  '540': {
    spaceBetween: 49,
    slidesPerView: 5,
  },
  '769': {
    spaceBetween: 100,
    slidesPerView: 7,
  },
}" v-if="partnersInfo">
          <swiper-slide v-for="(logo, index) in partnersInfo.logos" :key="index">
            <div class="ourPartners__image">
              <img :src="logo" class="image-contain" alt="Logo" />
            </div>
          </swiper-slide>

        </swiper>
      </div>
    </div>
  </section>
</template>
<script>
import { ref } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.scss";
import SwiperCore, { Autoplay } from "swiper";

// install Swiper modules
SwiperCore.use([Autoplay]);
export default {
  name: "ourPartners",
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    partnersInfo: ref(null),
  },
};
</script>