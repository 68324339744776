<template>
  <section v-if="dataNetwork" class="ourNetwork" data-section="dark">
    <div class="container container--custom container--custom__full">
      <div class="ourNetwork__grid">
        <div class="ourNetwork__info">
          <h3 class="subtitles subtitles--medium">{{ dataNetwork.network_title }}</h3>
          <p>{{ dataNetwork.network_description }}

          </p>
        </div>
        <div class="ourNetwork__map">
          <img :src="dataNetwork.network_image" alt="newwork image" class="image-contain">
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { ref } from '@vue/reactivity';
export default {
  name: "OurNetwork",
  props: {
    dataNetwork: ref(null)
  }
};
</script>