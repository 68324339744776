<template>
  <LoaderComponent :loading="isLoading" />
  <div v-if="!projects || errorMessage">{{ errorMessage }}</div>
  <template v-else>
    <div class="theWorkView" data-scroller>
      <Banner :bannerData="bannerData" />
      <TheProyects v-if="projects && projects.length > 0" :projects="projects" />
    </div>
  </template>
</template>
<script>
import axios from "axios";
import { ref, onMounted } from "vue";
import LoaderComponent from "@/components/globals/LoaderComponent";
import Header from "@/components/globals/Header";
import Banner from "@/components/theWork/Banner";
import TheProyects from "@/components/theWork/Proyects";
export default {
  name: "TheWork",
  components: {
    Header,
    Banner,
    TheProyects,
    LoaderComponent
  },

  setup() {
    const bannerData = ref([])
    const isLoading = ref(true)
    const errorMessage = ref('')
    const projects = ref([])

    const getData = async () => {
      try {
        const { data } = await axios.get("/pages/244");
        getBannerData(data.acf);
        errorMessage.value = null
      } catch (error) {
        errorMessage.value = "Ocurrió un error al cargar los datos"
      }
    };

    const getProyects = async () => {

      isLoading.value = true
      try {
        const { data } = await axios.get("/sancho-cases");
        projects.value = data

      } catch (error) {
        isLoading.value = false

      }
      isLoading.value = false
    };



    // get banners data
    const getBannerData = (dataResponse) => {
      bannerData.value = dataResponse
    }

    getData()
    getProyects()



    return {
      bannerData,
      isLoading,
      projects,
      errorMessage
    };
  },
};
</script>