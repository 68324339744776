<template>
  <div v-if="dataHow" class="theHow" data-section="light">
    <div class="container container--general" hidden>
      <div class="theHow__title">
        <h3 class="ttU">{{ dataHow.service_title }}</h3>
        <h2>{{ dataHow.service_description }}</h2>
      </div>
    </div>
    <!-- CAROUSEL THE HOW -->

    <div class="carousel-how" hidden>
      <swiper class="howsSlide" :autoplay="CAROUSEL_HOW.autoplay" :speed="CAROUSEL_HOW.speed"
        :centeredSlides="CAROUSEL_HOW.centeredSlides" :navigation="CAROUSEL_HOW.navigation"
        :breakpoints="CAROUSEL_HOW.breakpoints">
        <swiper-slide v-for="(image, idx) in dataHow.carousel" :key="idx">
          <div class="simpleCard" :style="`--indent:${idx + 1}`">
            <div class="simpleCard__image">
              <img :src="image.acf.thumbnail_image ?? require('@/assets/images/generals/sancho-placeholder.png')"
                :alt="image.title.rendered" class="image-cover">
              <div class="simpleCard__action">
                <router-link v-if="image.acf.text_button" :to="{
                  name: 'Detail The How',
                  params: { slug: image.slug },
                }" class="btn btn-white-opacity ttU">
                  {{ image.acf.text_button }}
                </router-link>
              </div>
            </div>
            <div class="simpleCard__content">
              {{ image.title.rendered }}
            </div>
          </div>
        </swiper-slide>

        <div class="customNavigation mt30">
          <div class="swiper-button-prev how-prev">
            <img src="@/assets/images/generals/previous.svg" class="image-contain" alt="arrow prev" />
          </div>
          <div class="swiper-button-next how-next">
            <img src="@/assets/images/generals/next.svg" class="image-contain" alt="arrow next" />
          </div>
        </div>
      </swiper>

    </div>
    <!-- CAROUSEL THE HOW -->
    <!-- CAMPAIGNS GRID -->
    <div class="container container--general" v-if="dataHow.results && dataHow.results.length > 0">
      <div class="howResults">
        <h3 vclass="ttU">{{ dataHow.results_title }}</h3>
        <div class="howResults__grid">
          <a :href="url" target="_blank" class="howResults__card"
            v-for="({ image, title, date, url }, index) in dataHow.results" :key="index">
            <div class="howResults__header">
              <div class="howResults__logo" v-if="image">
                <img :src="image" class="image-contain" :alt="`image for: ${title}`" />
              </div>
              <!-- <img class="howResults__arrow" src="@/assets/images/generals/leftArrowWh.svg" alt="" /> -->
              <h3>{{ title }}</h3>
            </div>
            <div class="howResults__body">
              <p>{{ date }}</p>
            </div>
          </a>
        </div>
      </div>
    </div>
    <!-- CAMPAIGNS GRID -->
  </div>
</template>
<script>
import { ref } from "vue";
// import Swiper core and required modules
import SwiperCore, { Navigation } from "swiper";
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';



import { CAROUSEL_HOW } from "@/helpers/sliders/slidersOptions";

export default {
  name: "TheHow",
  props: {},
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    dataHow: ref(null),
  },
  setup() {
    SwiperCore.use([Navigation]);
    return {
      CAROUSEL_HOW
    };
  },
};
</script>
