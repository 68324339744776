<template>
    <section v-if="loading" class="loading" :class="[loading ? 'slide-bottom' : 'slide-top']">
        <div class="loading__content ">
            <svg class="logoAnimation" id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1400 340">
                <g>
                    <path fill="#fc3103"
                        d="m1299.32,125.58c-13.86-13.72-33.42-22.2-56.01-22.18-22.6-.02-42.26,8.53-56.22,22.37-8.7,8.59-15.15,19.22-18.82,31.07h0c-4.92,14.8-4.96,30.79-.12,45.61h0c3.59,11.92,10.05,22.61,18.75,31.23,13.86,13.72,33.42,22.18,55.99,22.18h.06c22.57,0,42.22-8.57,56.17-22.37,13.96-13.84,22.26-32.95,22.24-53.86v-.4c.02-20.92-8.16-39.92-22.04-53.66Z" />
                    <path fill="#fc3103"
                        d="m1161.21,156.84c-3.59-11.53-9.94-21.76-18.55-29.95-13.84-13.2-33.46-21.1-56.37-21.1h-58.32v147.68h58.36c22.91,0,42.5-8.02,56.33-21.34,8.5-8.14,14.8-18.27,18.43-29.67l.02-.05c4.55-14.84,4.59-30.7.11-45.56h0Z" />
                    <path fill="#fc3103"
                        d="m1005.38,175.78c8.26-6.14,14.79-15.64,14.71-29.77v-.4c.03-11.99-4.93-22.25-13.75-29.17-8.8-6.94-21.19-10.63-36.14-10.63h-68.78v147.66h70.4c16.33,0,30.19-3.31,40.22-10.29,5.33-3.67,9.47-8.5,12.19-14.28l.7-1.79c4.27-10.9,4.02-23.06-.7-33.78-3.86-7.86-10.51-13.5-18.86-17.55Z" />
                    <path fill="#fc3103"
                        d="m878.83,175.78c8.26-6.14,14.79-15.64,14.71-29.77v-.4c.03-11.99-4.93-22.25-13.75-29.17-8.8-6.94-21.19-10.63-36.14-10.63h-68.78v147.66h70.4c16.33,0,30.19-3.31,40.22-10.29,5.33-3.67,9.47-8.5,12.19-14.28l.7-1.79c4.27-10.9,4.02-23.06-.7-33.78-3.86-7.86-10.51-13.5-18.86-17.55Z" />
                </g>
                <g>
                    <path fill="#fff"
                        d="m80.95,217.11l15.94-19.04c10.33,8.12,21.54,12.4,33.5,12.4,7.82,0,11.95-2.66,11.95-7.08v-.29c0-4.43-3.39-6.64-17.56-10.03-21.99-5.02-38.96-11.22-38.96-32.32v-.3c0-19.18,15.2-33.05,39.99-33.05,17.56,0,31.28,4.72,42.5,13.72l-14.31,20.22c-9.44-6.79-19.77-10.18-28.92-10.18-6.94,0-10.33,2.8-10.33,6.64v.3c0,4.72,3.54,6.79,18,10.03,23.61,5.17,38.37,12.84,38.37,32.17v.29c0,21.1-16.68,33.65-41.76,33.65-18.3,0-35.56-5.75-48.4-17.12Z" />
                    <path fill="#fff"
                        d="m217,128.42h27.59l43.98,104.04h-30.69l-7.38-18.59h-39.99l-7.38,18.59h-29.96l43.83-104.04Zm25.09,63.16l-11.51-29.22-11.66,29.22h23.17Z" />
                    <path fill="#fff"
                        d="m297.13,129.16h26.71l42.5,54.6v-54.6h28.33v103.3h-24.94l-44.27-56.67v56.67h-28.33v-103.3Z" />
                    <path fill="#fff"
                        d="m408.1,181.11v-.3c0-30.1,23.02-53.71,54.3-53.71,21.1,0,34.68,8.85,43.83,21.4l-21.54,16.67c-5.9-7.23-12.69-12.1-22.58-12.1-14.46,0-24.64,12.4-24.64,27.45v.29c0,15.5,10.18,27.6,24.64,27.6,10.77,0,17.12-5.02,23.32-12.54l21.54,15.35c-9.74,13.43-22.87,23.32-45.6,23.32-29.51,0-53.27-22.58-53.27-53.42Z" />
                    <path fill="#fff"
                        d="m517.89,129.16h28.63v38.66h36.74v-38.66h28.63v103.3h-28.63v-39.25h-36.74v39.25h-28.63v-103.3Z" />
                    <path fill="#fff"
                        d="m625.32,181.11v-.3c0-29.66,23.91-53.71,55.78-53.71s55.49,23.76,55.49,53.42v.29c0,29.66-23.91,53.71-55.78,53.71s-55.49-23.76-55.49-53.42Zm81.9,0v-.3c0-15.05-10.62-27.89-26.41-27.89s-26.27,12.69-26.27,27.59v.29c0,14.91,10.77,27.89,26.56,27.89s26.12-12.69,26.12-27.59Z" />
                </g>
            </svg>
        </div>

    </section>
</template>

<script>
import { ref } from '@vue/reactivity'
export default {
    name: 'LoaderComponent',
    props: {
        loading: ref(false),
    },
} 
</script>
