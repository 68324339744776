<!-- Delete this component when the information from Sancho is ready to be used. -->

<template>
  <div class="theWork" data-section="dark">
    <div v-if="dataWork" class="container container--general">
      <h3 class="ttU">{{ dataWork.case_title ?? 'The Work' }}</h3>
      <h2> {{ dataWork.case_description ?? 'Ideas que resuenan, ideas que incomodan, ideas que le importan a la gente.' }}
      </h2>
    </div>

    <div class="container plm15 prm15" v-if="dataWork.slider && dataWork.slider.length > 0">
      <swiper :slidesPerView="'auto'" :direction="'horizontal'" :centeredSlides="false" :centerInsufficientSlides="false"
        :mousewheel="false" :spaceBetween="20" :speed="800" :breakpoints="CAROUSEL_HOME_WORK.breakpoints"
        :navigation="CAROUSEL_HOME_WORK.navigation" class="workCarousel">
        <swiper-slide v-for="(work, index) in dataWork.slider" :key="index">
          <a :href="work.case_link" target="_blank" class="cardWork__box">
            <div class="cardWork__header">
              <div class="cardWork__figure">
                <img
                  :src="work.case_work_image ? work.case_work_image : require('@/assets/images/generals/sancho-placeholder.png')"
                  alt="Image" class="image-cover" />
              </div>
              <div class="cardWork__logo" v-if="work.logo_brand">
                <img :src="work.logo_brand" alt="Logo" class="image-contain" />
              </div>
            </div>
            <div class="cardWork__body">
              <p class="cardWork__title">{{ work.title_work_case }}</p>
              <div class="cardWork__content line-clamp-4">
                <p>
                  {{ work.description_work_case }}
                </p>
              </div>
              <div class="cardWork__footer mt20">
                <div class="cardWork__actions">
                  <img src="@/assets/images/generals/rightArrow.svg" alt="" />
                </div>
              </div>
            </div>
          </a>
        </swiper-slide>

        <!-- BUTTON VIEW MORE  -->
        <div class="customNavigation-dense ml30 mlm0">
          <div class="swiper-button-prev work-prev">
            <img src="@/assets/images/generals/prevArrow.svg" class="image-contain" alt="arrow prev" />
          </div>
          <div class="swiper-button-next work-next">
            <img src="@/assets/images/generals/nextArrow.svg" class="image-contain" alt="arrow next" />
          </div>
        </div>
      </swiper>


    </div>
  </div>
</template>
<script>
import { ref } from "vue";

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.scss";

// import Swiper core and required modules
import SwiperCore, { Navigation, Mousewheel } from "swiper";
import { CAROUSEL_HOME_WORK } from "@/helpers/sliders/slidersOptions";


// install Swiper modules
SwiperCore.use([Navigation, Mousewheel]);

export default {
  name: "TheWork",
  props: {},
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    dataWork: ref({}),
  },
  setup() {
    return {
      CAROUSEL_HOME_WORK,
    };
  },
};
</script>
