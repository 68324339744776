import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import TheHow from "../views/TheHow.vue";
import DetailTheHow from "../views/DetailTheHow.vue";
import SomosSancho from "../views/SomosSancho.vue";
import TheWork from "../views/TheWork.vue";
import DetailTheWork from "../views/DetailTheWork.vue";
import ThatIsHowItWorks from "../views/ThatIsHowItWorks.vue";
import DetailThatIsHowItWorks from "../views/DetailThatIsHowItWorks.vue";
import ContactUs from "../views/ContactUs.vue";
import Blog from "../views/Blog.vue";
import DetailBlog from "../views/DetailBlog.vue";
import DetailCategorie from "../views/DetailCategorie.vue";
import PageNotFound from "../views/PageNotFound.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/the-how",
    name: "The How",
    component: TheHow,
  },
  {
    path: "/the-how/:slug",
    name: "Detail The How",
    component: DetailTheHow,
  },
  {
    path: "/somos-sancho",
    name: "Somos Sancho",
    component: SomosSancho,
  },
  {
    path: "/the-work",
    name: "The Work",
    component: TheWork,
  },
  {
    path: "/contacto",
    name: "Contact Us",
    component: ContactUs,
  },
  {
    path: "/blog",
    name: "Blog",
    component: Blog,
  },
  {
    path: "/blog/:slug",
    name: "Detail Blog",
    component: DetailBlog,
  },
  {
    path: "/blog/categorie/:categorie",
    name: "Detail Categories",
    component: DetailCategorie,
  },
  {
    path: "/the-work/:slug",
    name: "Detail The Work",
    component: DetailTheWork,
    meta: { hideFooter: true },
  },
  {
    path: "/asi-se-trabaja",
    name: "Asi Se Trabaja",
    component: ThatIsHowItWorks,
  },
  {
    path: "/asi-se-trabaja/:slug",
    name: "DetailJob",
    component: DetailThatIsHowItWorks,
  },
  {
    path: "/:pathMatch(.*)*",
    component: PageNotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({ top: 0 });
      }, 2000);
    });
  },
});

export default router;
