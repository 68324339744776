<template>
  <section v-if="dataBanner" class="blog__banner" data-section="light">
    <swiper @slideChange="disabledArrows" @swiper="setControlledSwiper" :effect="'s'" :pagination="{
      type: 'progressbar',
    }" class="blog__banner__carousel" ref="carousel">
      <swiper-slide v-for="(post, index) in dataBanner" :key="index">
        <div class="blog__banner__card">
          <div class="blog__banner__card__bg">
            <picture v-if="post.acf.banner_desktop || post.acf.banner_mobile">
              <source media="(max-width: 767px)" :srcset="post.acf.banner_mobile" />
              <img :src="post.acf.banner_desktop" :alt="`banner ${post.title.rendered}`" />
            </picture>
          </div>
          <div class="blog__banner__card__container">
            <div class="blog__banner__card__title">
              <h2>
                {{ post.title.rendered }}
              </h2>
            </div>
            <div class="blog__banner__card__actions">
              <div class="blog__banner__card__actions__btn">
                <router-link class="btn btn--light ttU" :to="{ name: 'Detail Blog', params: { slug: post.slug } }">
                  leer artículo
                </router-link>
              </div>
              <div class="blog__banner__card__actions__arrows">
                <button
                  class="blog__banner__card__actions__arrows__arrow blog__banner__card__actions__arrows__arrow--prev"
                  :class="{
                    'detailTheWork__otherProyects__title__navs__arrows__prev--disabled':
                      isFirst,
                  }" @click="controlledSwiper.slidePrev()" :disabled="isFirst">
                  <img src="@/assets/images/generals/previous.svg" alt="arrow prev" />
                </button>
                <button
                  class="blog__banner__card__actions__arrows__arrow blog__banner__card__actions__arrows__arrow--next"
                  :class="{
                    'detailTheWork__otherProyects__title__navs__arrows__next--disabled':
                      isEnd,
                  }" @click="controlledSwiper.slideNext()" :disabled="isEnd">
                  <img src="@/assets/images/generals/next.svg" alt="arrow next" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </section>
</template>
<script>
import { ref, onMounted } from "vue";

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/swiper.scss";
import "swiper/components/effect-fade/effect-fade.scss";

import "swiper/components/pagination/pagination.scss";

// import Swiper core and required modules
import SwiperCore, { Pagination, EffectFade } from "swiper";

// install Swiper modules
SwiperCore.use([Pagination, EffectFade]);

export default {
  name: "Banner",
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    dataBanner: ref(null)
  },
  setup() {
    const controlledSwiper = ref(null);
    const isFirst = ref(false);
    const isEnd = ref(false);
    const numSlides = ref(null);
    const carousel = ref(null);

    const setControlledSwiper = (Swiper) => {
      controlledSwiper.value = Swiper;
    };
    onMounted(() => {
      disabledArrows();
    });

    function disabledArrows() {
      numSlides.value = controlledSwiper.value.el.children[1].children.length;
      if (controlledSwiper.value.activeIndex === 0) {
        isFirst.value = true;
        isEnd.value = false;
      } else if (controlledSwiper.value.activeIndex === numSlides.value - 1) {
        isFirst.value = false;
        isEnd.value = true;
      } else {
        isFirst.value = false;
        isEnd.value = false;
      }
    }

    return {
      setControlledSwiper,
      controlledSwiper,
      disabledArrows,
      isFirst,
      isEnd,
      carousel,
    };
  },
};
</script>
