<template>
    <section class="doubleImages" data-section="dark">
        <div class="doubleImages__picture" v-for="(image, idx) in unequalImages" :key="idx">
            <img :src="image" :alt="`content image ${idx}`" class="image-cover">
        </div>
    </section>
</template>

<script>
import { ref } from '@vue/reactivity'
export default {
    name: "ImagesWithSpaces",
    props: {
        unequalImages: ref('')
    }
}
</script>