<template>
  <div class="video">
    <div class="video__container">
      <div class="video__multiplayer">
        <video autoplay muted ref="player" class="video__multiplayer__video" @timeupdate="updateProgressBar">
          <source :src="data" type="video/mp4" />
          <source :src="data" type="video/ogg" />
        </video>
      </div>
      <div class="video__controls">
        <div class="container container--general container--general__small">
          <div class="video__controls__box">
            <div ref="play" class="video__controls__play" @click="playPauseVideo">
              <img src="@/assets/images/generals/play.svg" alt="" />
            </div>
            <progress ref="progress" class="video__controls__progress" min="0" max="100" value="0"
              @click.prevent="seek">
              0% played
            </progress>
            <div ref="replay" class="video__controls__replay" @click="replayVideo">
              <img src="@/assets/images/generals/replay.svg" alt="" />
            </div>
            <div ref="volume" class="video__controls__volume" @click="muteVolume">
              <img src="@/assets/images/generals/volume.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Header from "@/components/globals/Header";

import { ref, onMounted } from "vue";

export default {
  name: "Video",
  components: {
    Header
  },
  props: ['data'],
  setup() {
    const player = ref(null);
    const progress = ref(null);
    onMounted(() => {
    });

    const playPauseVideo = () => {
      if (player.value.paused || player.value.ended) {
        player.value.play();
      } else {
        player.value.pause();
      }
    };

    const muteVolume = () => {
      if (player.value.muted) {
        player.value.muted = false;
      } else {
        player.value.muted = true;
      }
    };

    const replayVideo = () => {
      resetPlayer();
      player.value.play();
    };

    const resetPlayer = () => {
      player.value.currentTime = 0;
    };

    const seek = (e) => {
      var percent = e.offsetX / progress.value.offsetWidth;
      player.value.currentTime = percent * player.value.duration;
      e.target.value = Math.floor(percent / 100);
      e.target.innerHTML = progress.value + "% played";
    };

    const updateProgressBar = () => {
      const percentage = Math.floor(
        (100 / player.value.duration) * player.value.currentTime
      );
      progress.value.value = percentage;
      progress.value.innerHTML = percentage + "% played";
    };

    return {
      player,
      progress,
      playPauseVideo,
      muteVolume,
      replayVideo,
      resetPlayer,
      updateProgressBar,
      seek,
    };
  },
};
</script>