<template>
  <section class="detailBlog__banner" data-section="light">
    <div class="detailBlog__banner__bg">
      <picture>
        <source media="(max-width: 820px)" :srcset="bannerInfo.banner_mobile" />
        <img :src="bannerInfo.banner_desktop" :alt="`Banner ${bannerInfo.title}`" />
      </picture>
    </div>
    <div class="detailBlog__banner__title">
      <h1>
        {{ bannerInfo.title }}
      </h1>
    </div>
  </section>
</template>
<script>
import { ref } from '@vue/reactivity'
export default {
  name: 'BannerBlogDetail',
  props: {
    bannerInfo: ref(null)
  },
}
</script>
