<template>
  <section class="blog__mainTitle" data-section="dark">
    <div class="blog__mainTitle__container">
      <h1>
        {{ blogTitle }}
      </h1>
      <p>
        {{ blogDescription }}
      </p>
    </div>
  </section>
</template>

<script>
import { ref } from '@vue/reactivity'
export default {
  name: "MainTitle",
  props: {
    blogTitle: ref(''),
    blogDescription: ref(''),
  }
}
</script>