import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./scss/main.scss";
import axios from "axios";
import VueAxios from "vue-axios";
import VueAwesomePaginate from "vue-awesome-paginate";
import "vue-awesome-paginate/dist/style.css";
import Vue3Lottie from 'vue3-lottie'

console.log(process.env, process.env.VUE_APP_API_URL)
axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.headers.common["Authorization"] = "";
createApp(App)
  .use(store)
  .use(router)
  .use(VueAxios, axios)
  .use(VueAwesomePaginate)
  .use(Vue3Lottie)
  .mount("#app");



