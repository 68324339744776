<template >
    <section class="loading" :class="[isPresentation ? 'fade-in-fwd' : 'slide-top']">

        <div class="loading__video">
            <Vue3Lottie class="loading__animation"
                :animationData="isMobile ? require('@/assets/loaders/loader_mobile2.json') : require('@/assets/loaders/loader_desktop.json')"
                :height="'100%'" :width="'100%'" @onLoopComplete="loaderComplete" :pauseAnimation="!isPresentation" />
        </div>
    </section>
</template>
<script>
import { ref } from 'vue';
import { Vue3Lottie } from 'vue3-lottie'
export default {
    name: 'loaderPresentation',
    components: { Vue3Lottie },
    props: {
        loading: ref(false),
    },
    setup({ loading }) {
        const isPresentation = ref(true)
        const isMobile = ref(false)

        const handleResize = () => {
            let screenWidth = window.innerWidth
            if (screenWidth < 768) {
                isMobile.value = true
            } else {
                isMobile.value = false
            }
        }
        window.addEventListener("resize", handleResize)
        handleResize()

        const loaderComplete = () => {
            isPresentation.value = false
        }

        return {
            isMobile,
            isPresentation,
            loaderComplete
        }
    }
}
</script>
