<template>
    <section class="doubleImages doubleImages--vertical" data-section="dark">
        <div class="doubleImages__picture" v-for="(image, idx) in verticalImages" :key="idx">
            <img :src="image" class="image-cover" :alt="`vertical image ${idx}`">
        </div>
    </section>
</template>

<script>
import { ref } from '@vue/reactivity'
export default {
    name: "LeftImages",
    props: {
        verticalImages: ref('')
    }
}
</script>