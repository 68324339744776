<template>

  <LoaderComponent :loading="isLoading" />
  <div v-if="!bannerInfo">error</div>
  <template v-else>
    <div class="thatIsHowItWorks" data-scroller>
      <!-- <Header :isColor="true" :styleHeader="styleHeader" /> -->
      <Banner :bannerInfo="bannerInfo" />
      <labelComponent :labelText="labelText" />
      <Vacancies />
      <section class="thatIsHowItWorks__form">
        <Form />
      </section>
      <Gallery :images="galleryData" />
    </div>
  </template>
</template>
<script>
import { ref } from "vue";
import axios from "axios";
/* Import Components */
import LoaderComponent from "@/components/globals/LoaderComponent";
import Header from "@/components/globals/Header";
import Banner from "@/components/globals/Banner";
import labelComponent from "../components/theHow/labelComponent.vue";
import Vacancies from "../components/ThatIsHowItWorks/Vacancies.vue";
import Form from "../components/globals/Form.vue";
import Gallery from "../components/ThatIsHowItWorks/Gallery.vue";

export default {
  name: "ThatIsHowItWorks",
  components: {
    LoaderComponent,
    Header,
    Banner,
    labelComponent,
    Vacancies,
    Form,
    Gallery,
  },

  setup() {
    const bannerInfo = ref({})
    const labelText = ref('')
    const isLoading = ref(false)
    const galleryData = ref([])

    const getData = async () => {
      isLoading.value = true
      const { data } = await axios.get("/pages/216");
      getBannerInfo(data.acf);
      getLabelText(data.acf);
      galleryData.value = data.acf.images

      isLoading.value = false
    };

    const getBannerInfo = (dataResponse) => {
      const { img_mobile,
        img_desktop,
        title,
        subtitle,
        description } = dataResponse

      bannerInfo.value = {
        img_mobile,
        img_desktop,
        title,
        subtitle,
        description
      }
    }

    const getLabelText = (dataResponse) => {
      labelText.value = dataResponse.banner
    }


    getData()


    return {
      bannerInfo,
      labelText,
      isLoading,
      galleryData
    };
  },
};
</script>
