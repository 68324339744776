<script>

import { useRoute } from 'vue-router';
import { computed } from "vue";

export default {

  setup() {

    const route = useRoute();

    const getBreadcrumbs = () => {

      const pathArray = route.path.split("/");
      pathArray.shift();

      return pathArray.reduce((breadcrumbArray, path, key) => {
        let url = '';
        pathArray.forEach((value, index) => {
          url = index <= key ? `${url}/${value}` : url;
        });

        breadcrumbArray = [...breadcrumbArray, { text: path.replaceAll('-', ' '), link: url }]

        return breadcrumbArray;

      }, [])

    }

    const crumbs = computed(() => {
      return getBreadcrumbs(route);
    });

    return {
      crumbs,
    }

  }
};
</script>

<template>
  <section class="breadcrumbs">
    <ul class="breadcrumbs__box">
      <li class="breadcrumbs__breadcrumb">
        <router-link to="/"> Home </router-link>
      </li>
      <template v-for="crumb in crumbs">
        <li v-if="crumb.text != ''" class="breadcrumbs__breadcrumb">
          <router-link :to="crumb.link">
            {{ crumb.text }}
          </router-link>
        </li>
      </template>
    </ul>
  </section>
</template>