<template>
    <div class="bannerWork">
        <div class="bannerWork-image">
            <picture v-if="bannerInfo.banner_desktop || bannerInfo.banner_mobile">
                <source media="(max-width: 767px)" :srcset="bannerInfo.banner_mobile" />
                <img class="image-cover" :src="bannerInfo.banner_desktop" :alt="bannerInfo.client_name" />
            </picture>
        </div>
        <div class="bannerWork-content">
            <h1 class="bannerWork-title text-h1">{{ bannerInfo.title }}</h1>
            <div class="bannerWork-item">
                <p class="text-caption text-gray3">cliente</p>
                <p class="text-h3 text-black2">{{ bannerInfo.client_name }}</p>

            </div>
            <div class="bannerWork-item">
                <p class="text-caption text-gray3">Fecha</p>
                <p class="text-subtitle text-black2 ttU">{{ bannerInfo.work_date }}</p>

            </div>
            <div class="bannerWork-item" v-if="categoriePills && categoriePills.length > 0">
                <p class="text-caption text-gray3">Categorias</p>
                <ul class="detailTheWork__banner__right__categories__box">
                    <li class="detailTheWork__banner__right__categories__box__item"
                        v-for="(cat, index) in categoriePills" :key="index">
                        {{ cat }}
                    </li>
                </ul>

            </div>
        </div>
    </div>
</template>
<script>
import { ref } from 'vue';

export default {
    name: "Banner",
    props: {
        bannerInfo: ref(null),
        categoriePills: ref(null)
    },
}
</script>