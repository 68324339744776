<template>
  <section class="banner" data-section="light">
    <div class="banner__bg">
      <picture>
        <source media="(max-width: 768px)"
          :srcset="bannerInfo.img_mobile?bannerInfo.img_mobile:require('@/assets/images/generals/sancho-placeholder.png')" />
        <img class="image-cover"
          :src="bannerInfo.img_desktop?bannerInfo.img_desktop:require('@/assets/images/generals/sancho-placeholder.png')"
          alt="" />
      </picture>
    </div>
    <div class="banner__content">
      <h1 v-if="bannerInfo.title">{{ bannerInfo.title }}</h1>
      <h2 v-if="bannerInfo.subtitle">{{ bannerInfo.subtitle }}</h2>
    </div>
    <div class="banner__description">
      <p v-if="bannerInfo.description">
        {{ bannerInfo.description }}
      </p>
    </div>
  </section>
</template>
<script>
import { ref } from "vue";
export default {
  name: "Banner",
  props: {
    bannerInfo: ref(null),
  },
};
</script>
