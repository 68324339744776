<template>
    <Transition duration="550" name="nested">
        <section :class="`modal modal-${variant}`" v-if="toggleModal">
            <div :class="[toggleModal ? 'fade-in-fwd' : '', 'modal__overlay']"></div>
            <div :class="[toggleModal ? 'slide-bottom' : '', 'modal__content inner']">
                <div class="modal__icon">
                    <img v-if="variant === 'success'" src="@/assets/icons/success.svg" alt="icon" />
                    <img v-else src="@/assets/icons/warning-info.svg" alt="icon" />
                </div>
                <div class="modal__header">
                    <button class="modal__close" @click="closeModal">
                        X
                    </button>
                </div>
                <h4 class="modal__title text-h3 ">{{ infoModal?.title }}</h4>
                <p class="modal__subtitle text-body">{{ infoModal?.content }}</p>
                <button type="button" class="btn btn--black ttU" @click="closeModal">
                    {{ infoModal?.button }}
                </button>
            </div>
        </section>
    </Transition>
</template>
<script>
import { ref, computed } from 'vue';
export default {
    name: "ModalInfo",
    props: {
        variant: {
            type: String,
            required: true,
            default: "success",
        },
        isOpen: {
            type: Boolean,
            required: false,
            default: false,
        },

    },
    setup() {

        const toggleModal = ref(false);
        const infoModal = ref({});
        const closeModal = () => {
            toggleModal.value = false;

        };


        return {
            closeModal,
            toggleModal,
            infoModal
        };
    },
}
</script>
