import axios from "axios";
import { ref } from "vue";

const useBlog = () => {
  const bannerPost = ref({});
  const featuredPosts = ref({});
  const listPosts = ref({});
  const blogACF = ref({});
  const isLoading = ref(false);
  const errorMessage = ref();

  //   request ACF blog page
  const getDataBlog = async () => {
    try {
      const { data } = await axios.get("/pages/191");
      getBlogACF(data.acf);
      errorMessage.value = null;
    } catch (error) {
      errorMessage.value = "Ocurrió un error al cargar la data";
    }
    isLoading.value = false;
  };
  // get the custom fields from blog page
  const getBlogACF = (data) => {
    blogACF.value = {
      ...data,
    };
  };

  //   request posts information
  const getDataPosts = async (postParam = "/posts/") => {
    isLoading.value = true;
    try {
      const { data } = await axios.get(postParam);
      getBannerPost(data);
      getFeaturedPost(data);
      listPosts.value = data;
      errorMessage.value = null;
    } catch (error) {
      errorMessage.value = "Ocurrió un error al cargar la data";
    }
    isLoading.value = false;
  };

  const getBannerPost = (data) => {
    const bannerLimit = 4;
    if (data.length > bannerLimit) {
      bannerPost.value = data.slice(0, bannerLimit);
    }
    if (data.length < bannerLimit) {
      bannerPost.value = data;
    }
  };

  //   Get featured posts
  const getFeaturedPost = (data) => {
    const features = data
      .filter((item) => {
        if (item.acf.featured) {
          return item;
        }
      })
      .map((featured) => ({
        title: featured.title,
        acf: featured.acf,
        slug: featured.slug,
      }));
    featuredPosts.value = features;
  };

  getDataBlog();

  return {
    isLoading,
    errorMessage,
    bannerPost,
    featuredPosts,
    blogACF,
    listPosts,
    getDataPosts,
  };
};

export default useBlog;
