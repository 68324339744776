<template>
  <LoaderComponent :loading="isLoading" />
  <div class="errorSite" v-if="!bannerPost || errorMessage">{{ errorMessage }}</div>
  <template v-else>
    <div class="blog">
      <Banner :dataBanner="bannerPost" />
      <Featured :featuredPosts="featuredPosts" :featuredTitle="blogACF.featured_title_section" />
      <MainTitle :blogTitle="blogACF.blog_title" :blogDescription="blogACF.blog_description" />
      <TheMostNew :postsList="listPosts" :postsTitle="blogACF.most_recent_title"
        :categoryTitle="blogACF.find_out_more_title" :categorieList="categorieList" />
    </div>
  </template>
</template>
<script>
import LoaderComponent from "@/components/globals/LoaderComponent";
import Header from "@/components/globals/Header";
import Banner from "@/components/blog/Banner";
import Featured from "@/components/blog/Featured";
import MainTitle from "@/components/blog/MainTitle";
import TheMostNew from "@/components/blog/TheMostNew";
import useBlog from "../composables/blog/useBlog";
import useCategories from "../composables/categories/useCategories";

export default {
  name: "Blog",
  components: {
    Header,
    Banner,
    Featured,
    MainTitle,
    TheMostNew,
    LoaderComponent
  },
  setup() {
    // get blog information 
    const {
      bannerPost,
      blogACF,
      errorMessage,
      featuredPosts,
      getDataPosts,
      isLoading,
      listPosts,
    } = useBlog();



    // Get categories list
    const { categorieList } = useCategories()

    const getData = async () => {
      await getDataPosts();
    };
    getData();

    return {
      bannerPost,
      blogACF,
      categorieList,
      errorMessage,
      featuredPosts,
      getDataPosts,
      isLoading,
      listPosts,
    };
  },
};
</script>