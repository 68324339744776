<template>
  <section v-if="infoVacancie" class="detailThatIs__vacancie" data-section="dark">
    <div class="detailThatIs__vacancie__detail">
      <div class="detailThatIs__vacancie__detail__top">
        <h1>{{ infoVacancie.title }}</h1>
        <strong class="ttC"> Vigencia {{ infoVacancie.date }} </strong>
      </div>
      <div v-html="infoVacancie.description">
      </div>
    </div>
  </section>
</template>
<script>
import { ref } from '@vue/reactivity';
export default {
  name: "infoVacancie",
  props: {
    infoVacancie: ref(null)
  }
};
</script>