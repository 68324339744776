<template>
  <section class="theWorkView__banner" data-section="light">
    <div class="container container--small">
      <div class="theWorkView__banner__grid">
        <div class="theWorkView__banner__title">
          <h1 v-if="bannerData">{{ bannerData.title }}</h1>
        </div>
        <div class="theWorkView__banner__description">
          <p v-if="bannerData">
            {{ bannerData.description }}
          </p>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { ref } from "vue";
export default {
  name: "Banner",
  props: {
    bannerData: ref(null),
  },
};
</script>
