<template>
  <section class="categories" :class="{ 'categories--active': showModal }">
    <div class="categories__container">
      <ul class="categories__list">
        <li
          v-for="(category, index) in categories"
          :key="index"
          class="categories__list__item"
          :class="{
            'categories__list__item--active':
              category.isSelected,
          }"
          :data-category="category.name"
          @click="aplFilter(category)"
        >
          {{ category.name }}
        </li>
      </ul>
    </div>
  </section>
</template>
<script>
import { ref, onMounted } from "vue";
export default {
  name: "Categories",
  props: {
    showModal: ref(Boolean),
    categories: ref([]),
    aplFilter: Function,
  },
  setup(props) {
    onMounted(() => {
    });
    return {
        
    }
  },
};
</script>