<template>
  <section class="ourAdvantages" data-section="light">
    <div class="container container--general">
      <div class="ourAdvantages__grid">
        <div class="ourAdvantages__left">
          <div class="ourAdvantages__left__content">
            <h2 class="subtitles subtitles--medium subtitles--medium__white">
              {{ advantageData.advantage_title }}
            </h2>
            <p>
              {{ advantageData.advantage_subtitle }}
            </p>
          </div>
        </div>

        <div class="ourAdvantages__right">
          <div v-if="advantageData.advantages && advantageData.advantages.length > 0"
            class="ourAdvantages__right__advantages">
            <div v-for="(advantage, index) in advantageData.advantages" :key="index"
              class="ourAdvantages__right__advantages__card">
              <div class="ourAdvantages__right__advantages__card__thumbnail">
                <img :src="advantage.image" alt="Advantages" />
              </div>
              <div class="ourAdvantages__right__advantages__card__info">
                <p v-if="index <= 8">0{{ index + 1 }}.</p>
                <p v-else>{{ index + 1 }}.</p>
                <h3>{{ advantage.title }}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { ref, onBeforeUpdate, onMounted } from "vue";

export default {
  name: "OurAdvantages",
  props: {
    advantageData: ref(null),
  },
  setup() {
    const advantagesBoxes = ref([]);
    onBeforeUpdate(() => {
      advantagesBoxes.value = [];
    });
    onMounted(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            entry.target.classList.toggle(
              "ourAdvantages__right__advantages__card--scrolled",
              entry.isIntersecting
            );
          });
        },
        {
          threshold: 0.8,
        }
      );
      advantagesBoxes.value.forEach((advantage) => {
        observer.observe(advantage);
      });
    });

    return {
      advantagesBoxes,
    };
  },
};
</script>
