export const headerChange = async () => {
  const htmlTag = document.querySelector("html");
  const header = document.querySelector(".header");
  let sections = document.querySelectorAll("[data-section]");

  const intervalSections = setInterval(() => {

    if (sections.length > 0) {
        clearTimeout(intervalSections);

        const sectionObserver = new IntersectionObserver(callback, {
          rootMargin: "0px",
          threshold: 0.5,
        });

        sections.forEach((change) => {
          sectionObserver.observe(change);
        });

    } else {
      sections = document.querySelectorAll("[data-section]");
    }
  }, 100);

  const callback = (entries) => {

    entries.forEach((entry) => {
      if (!entry.isIntersecting) {
        return;
      }
      if (entry.isIntersecting) {
        htmlTag.setAttribute("data-theme", entry.target.dataset.section);
      }
      headerScroll();
    });

  };

  const headerScroll = () => {
    let prevScroll = window.scrollY || document.scrollTop,
      curScroll,
      direction = 0,
      prevDirection = 0;

    const checkScroll = () => {
      curScroll = window.scrollY || document.scrollTop;
      if (curScroll > prevScroll) {
        //scrolled up
        direction = 2;
      } else if (curScroll < prevScroll) {
        //scrolled down
        direction = 1;
      }

      if (direction !== prevDirection) {
        toggleHeader(direction, curScroll);
      }
      // if (window.scrollY < header.offsetHeight) {
      //   header.classList.remove("shrink");
      // }

      prevScroll = curScroll;
    };

    let toggleHeader = function (direction, curScroll) {
      if (direction === 2 && curScroll > header.offsetHeight) {
        header.classList.add("hide");
        prevDirection = direction;
      } else if (direction === 1) {
        header.classList.remove("hide");
        prevDirection = direction;
      }
    };

    window.addEventListener("scroll", checkScroll);
  };
};
