<template>
  <section v-if="result" class="container container--limit" data-section="dark">
    <div class="ourHolding">
      <div class="ourHolding__title">
        <div class="ourHolding__title__info">
          <h3 class="subtitles subtitles--medium">
            {{ result.our_holding_title }}
          </h3>
          <p>
            {{ result.our_holding_subtitle }}
          </p>
        </div>
      </div>
      <div class="ourHolding__logo">
        <img :src="result.our_holding_logo" alt="Grupo Sancho" />
      </div>
      <!-- HOLDING ACCORDION  -->
      <div class="accordion" v-for="(agency, index) in result.agencies" :key="index">
        <div class="accordion__header" @click="toggleAccordion(index)">
          <div class="accordion__logo">
            <img :src="agency.logo ? agency.logo : require('@/assets/images/generals/sancho-placeholder.png')"
              alt="Agency logo" class="image-contain" :class="{
                'grayScale':
                  !agency.isActive,
              }" />
          </div>
          <div class="accordion__title">
            <h3>{{ agency.short_description }}</h3>
          </div>
          <div class="accordion__icon">
            <img
              :src="agency.isActive ? require('@/assets/images/generals/line.svg') : require('@/assets/images/generals/plus.svg')"
              class="image-contain" alt="Toggle icon" />
            <!-- <img v-else src="@/assets/images/generals/line.svg" alt="" /> -->
          </div>
        </div>
        <transition name="slideDown">
          <div class="accordion__body" v-if="agency.isActive">
            <div class="accordion__body__top">
              <div class="accordion__body__web">
                <a class="ttU btnSimple btnSimple--black" :href="agency.url">
                  {{ agency.link_text }}
                  <img src="@/assets/images/generals/leftArrowBold.svg" alt="Arrow" />
                </a>
              </div>
              <div class="accordion__body__content">
                <h3>{{ agency.name }}</h3>
                <p>
                  {{ agency.description }}
                </p>
              </div>
              <div class="accordion__body__social">
                <ul class="accordion__body__social__box">
                  <li v-for="(network, index) in agency.social_networks" :key="index" :id="`social-${index}`" class="
                        accordion__body__social__item
                      ">
                    <a :href="network.url">
                      <img
                        :src="network.logo ? network.logo : require('@/assets/images/generals/sancho-placeholder.png')"
                        alt="Agency logo" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="accordion__body__banner">
              <img :src="agency.image ? agency.image : require('@/assets/images/generals/sancho-placeholder.png')"
                class="image-cover" alt="Agency image banner" />
            </div>
          </div>
        </transition>
      </div>
      <!-- HOLDING ACCORDION  -->
    </div>
  </section>
</template>
<script>
import { ref } from "vue";

export default {
  name: "OurHolding",
  props: {
    result: ref(null)
  },
  setup(props) {
    const dataAccordion = ref({})
    dataAccordion.value = props.result
    const toggleAccordion = (id) => {
      dataAccordion.value.agencies.map((agency, index) => {
        agency.isActive =
          index === id ? !agency.isActive : (agency.isActive = false);

      });
    }
    return {
      toggleAccordion
    }
  },

};
</script>