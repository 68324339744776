import { ref } from "vue";
import axios from "axios";

const useCategories = (categorieParam = "/categories") => {
  const categorieList = ref([]);
  const errorMessage = ref("");

  const getCategories = async () => {
    try {
      const { data } = await axios.get(categorieParam);
      categorieList.value = data;
    } catch (error) {
      errorMessage.value = "Ocurrió un error al cargar las categorias";
    }
  };
  getCategories();

  return {
    categorieList,
    errorMessage,
  };
};
export default useCategories;
