<template>
  <LoaderComponent :loading="isLoading" />
  <div v-if="!ourTeamInfo || errorMessage">{{ errorMessage }}</div>
  <template v-else>
    <div class="somosSancho" data-scroller>
      <Banner :bannerInfo="bannerInfo" />
      <labelComponent :labelText="labelText" />
      <OurAdvantages :advantageData="advantageSection" />
      <ThePeople :membersInfo="membersInfo" />
      <OurTeam :ourTeamInfo="ourTeamInfo" />
      <OurPartners :partnersInfo="partnersInfo" />
      <div class="divider"></div>
      <!--<IdeasInTime /> -->
    </div>
  </template>
</template>
<script>
import { ref, onMounted } from "vue";
// Import Components
import LoaderComponent from "@/components/globals/LoaderComponent";
import Header from "@/components/globals/Header";
import Banner from "@/components/globals/Banner";
import labelComponent from "@/components/theHow/labelComponent";
import OurAdvantages from "@/components/weAreSancho/OurAdvantages";
import ThePeople from "@/components/weAreSancho/ThePeople";
import OurTeam from "@/components/weAreSancho/OurTeam";
import OurPartners from "@/components/weAreSancho/OurPartners";
import IdeasInTime from "@/components/weAreSancho/IdeasInTime";
import axios from "axios";

export default {
  name: "SomosSancho",
  components: {
    LoaderComponent,
    Header,
    Banner,
    labelComponent,
    OurAdvantages,
    ThePeople,
    OurTeam,
    OurPartners,
    IdeasInTime,
  },

  setup() {
    const bannerInfo = ref({})
    const labelText = ref('')
    const advantageSection = ref({})
    const membersInfo = ref({})
    const partnersInfo = ref({})
    const ourTeamInfo = ref({})
    const isLoading = ref(false)
    const errorMessage = ref('')


    const getData = async () => {
      isLoading.value = true
      try {
        const { data } = await axios.get("/pages/242");
        getBannerInfo(data.acf);
        getLabelText(data.acf);
        getAdvantageSection(data.acf);
        getMembersInfo(data.acf);
        getPartnersInfo(data.acf);
        getOurTeamInfo(data.acf);
        errorMessage.value = null
      } catch (error) {

        errorMessage.value = 'Ocurrió un error al cargar los datos'
        isLoading.value = false
      }
      isLoading.value = false
    };

    const getBannerInfo = (dataResponse) => {
      const { img_mobile,
        img_desktop,
        title,
        subtitle,
        description } = dataResponse

      bannerInfo.value = {
        img_mobile,
        img_desktop,
        title,
        subtitle,
        description
      }
    }

    const getLabelText = (dataResponse) => {
      labelText.value = dataResponse.banner
    }
    const getAdvantageSection = (dataResponse) => {
      const { advantage_title, advantage_subtitle, advantages } = dataResponse
      advantageSection.value = { advantage_title, advantage_subtitle, advantages }
    }
    const getMembersInfo = (dataResponse) => {
      const { people_title, people_subtitle, people } = dataResponse
      membersInfo.value = { people_title, people_subtitle, people }
    }
    const getPartnersInfo = (dataResponse) => {
      const { our_partners_title, our_partners_subtitle, logos } = dataResponse
      partnersInfo.value = { our_partners_title, our_partners_subtitle, logos }
    }
    const getOurTeamInfo = (dataResponse) => {
      const { our_team_title, our_team_quantity } = dataResponse
      ourTeamInfo.value = { our_team_title, our_team_quantity }
    }

    getData()
    onMounted(() => {

    });

    return {
      isLoading,
      errorMessage,
      bannerInfo,
      labelText,
      advantageSection,
      membersInfo,
      partnersInfo,
      ourTeamInfo
    };
  },
};
</script>
