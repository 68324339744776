<template>
  <section class="video" data-section="light">
    <div class="video__container">
      <div class="video__multiplayer">
        <video ref="player" class="video__multiplayer__video" @timeupdate="updateProgressBar" @play="changeButtonPlay"
          @pause="changeButtonPause" playsinline autoplay muted loop>
          <template v-if="result.load_video">
            <source :src="isDesktop ? result.video_desktop_url : result.video_mobile_url" type="video/mp4" />
          </template>
          <template v-else>
            <source :src="isDesktop ? result.video_desktop : result.video_mobile" type="video/mp4" />
          </template>
        </video>
      </div>

      <div class="video__controls">
        <div class="container container--general container--general__small">
          <div class="video__controls__box">
            <div ref="play" class="video__controls__play" @click="playPauseVideo">
              <img :src="
                isPlay
                  ? require('@/assets/images/generals/play.svg')
                  : require('@/assets/images/generals/pause.svg')
              " alt="toggle play/pause video" />
            </div>
            <progress ref="progress" class="video__controls__progress" min="0" max="100" value="0"
              @click.prevent="seek">
              0% played
            </progress>
            <div ref="replay" class="video__controls__replay" @click="replayVideo">
              <img src="@/assets/images/generals/replay.svg" alt="" />
            </div>
            <div ref="volume" class="video__controls__volume" @click="muteVolume">
              <img :src="
                isMute
                  ? require('@/assets/images/generals/volume.svg')
                  : require('@/assets/images/generals/mute.svg')
              " alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { ref, onMounted, onBeforeUnmount, onUpdated, onUnmounted } from "vue";
export default {
  name: "Video",
  props: {
    result: ref(null),
  },
  setup() {
    const player = ref(null);
    const progress = ref(null);
    const isPlay = ref(Boolean);
    const isMute = ref(Boolean);
    const isDesktop = ref(null);

    onResize();

    onBeforeUnmount(() => {
      window.removeEventListener("resize", onResize);
    });
    onUpdated(() => {
      if (player.value.muted) {
        isMute.value = false;
      }
      window.addEventListener("scroll", pauseScrollVideo);
    });
    onMounted(() => { });
    onUnmounted(() => {
      window.removeEventListener("scroll", pauseScrollVideo);
    });

    function pauseScrollVideo() {
      let scrollTop = window.innerHeight;
      let bottomVideo = player.value.getBoundingClientRect().bottom;
      bottomVideo < scrollTop - scrollTop + 2 ? player.value.pause() : player.value.play()
    }

    const playPauseVideo = () => {
      if (player.value.paused || player.value.ended) {
        changeButtonPause();
        player.value.play();
      } else {
        changeButtonPlay();
        player.value.pause();
      }
    };

    const muteVolume = () => {
      if (player.value.muted) {
        changeButtonMute(true);
        player.value.muted = false;
      } else {
        changeButtonMute(false);
        player.value.muted = true;
      }
    };

    const replayVideo = () => {
      resetPlayer();
      player.value.play();
    };

    const resetPlayer = () => {
      player.value.currentTime = 0;
    };

    const seek = (e) => {
      var percent = e.offsetX / progress.value.offsetWidth;
      player.value.currentTime = percent * player.value.duration;
      e.target.value = Math.floor(percent / 100);
      e.target.innerHTML = progress.value + "% played";
    };

    const updateProgressBar = () => {
      const percentage = Math.floor(
        (100 / player.value.duration) * player.value.currentTime
      );
      progress.value.value = percentage;
      progress.value.innerHTML = percentage + "% played";
    };

    const changeButtonPlay = () => {
      isPlay.value = false;
    };
    const changeButtonPause = () => {
      isPlay.value = true;
    };
    const changeButtonMute = (value) => {
      isMute.value = value;
    };
    function onResize() {
      window.addEventListener("resize", onResize);
      if (window.innerWidth >= 769) {
        isDesktop.value = true;
      } else {
        isDesktop.value = false;
      }
    }

    return {
      player,
      progress,
      playPauseVideo,
      muteVolume,
      replayVideo,
      resetPlayer,
      updateProgressBar,
      seek,
      changeButtonPlay,
      changeButtonPause,
      isPlay,
      changeButtonMute,
      isMute,
      isDesktop,
      onResize,
      pauseScrollVideo,
    };
  },
};
</script>
