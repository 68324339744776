<template>
  <section class="blog__theMostNew" data-section="dark">
    <div class="container container--general container--general__small">
      <div class="blog__theMostNew__container">
        <div class="blog__theMostNew__posts">
          <h2>{{ postsTitle }}</h2>
          <div class="blog__theMostNew__posts__box ">
            <router-link :to="{ name: 'Detail Blog', params: { slug: post.slug } }"
              class="blog__theMostNew__posts__single" v-for="(post, index) in postsList" :key="index">
              <div class="blog__theMostNew__posts__single__thumbnail">
                <img :src="post.acf.banner_desktop" class="image-cover" :alt="post.title.rendered" />
              </div>
              <div class="blog__theMostNew__posts__single__body text-black">
                <h3 class="line-clamp-2">
                  {{ post.title.rendered }}
                </h3>
                <div class="line-clamp-2" v-html="post.excerpt.rendered"></div>
                <div class="taR mt30">
                  <svg width="21" height="14" viewBox="0 0 21 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M20.6 6.99998H0.600037M20.6 6.99998L14.2 0.599976L20.6 6.99998ZM20.6 6.99998L14.2 13.4L20.6 6.99998Z"
                      stroke="#343434" />
                  </svg>
                </div>

              </div>
            </router-link>
          </div>
        </div>
        <div class="blog__theMostNew__categories" v-if="categorieList">
          <h3 class="ttU">{{ categoryTitle }}</h3>
          <CategorieList :categorieList="categorieList" />
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { ref } from '@vue/reactivity';
import CategorieList from "@/components/globals/Categories";
export default {
  name: "TheMostNew",
  components: {
    CategorieList
  },
  props: {
    postsTitle: ref(''),
    categoryTitle: ref(''),
    postsList: ref({}),
    categorieList: ref([])
  }
};
</script>