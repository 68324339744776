<template>
  <LoaderComponent :loading="isLoading" />
  <div v-if="!dataNetwork || errorMessage">{{ errorMessage }}</div>
  <template v-else>
    <div class="theHowView" data-scroller>
      <Banner :bannerInfo="bannerInfo" />
      <labelComponent :labelText="labelText" />
      <OurWay :scrollSection="handleAnchorScroll" :result="ourWayInfo" />
      <OurWayWork :result="ourWayInfo" />
      <OurHolding :result="holdingInfo" />
      <OurNetwork :dataNetwork="dataNetwork" />
    </div>
  </template>
</template>
<script>
import { ref } from "vue";
import axios from "axios";
import LoaderComponent from "@/components/globals/LoaderComponent";
import Header from "@/components/globals/Header";
import Banner from "@/components/globals/Banner";
import labelComponent from "@/components/theHow/labelComponent";
import OurWay from "@/components/theHow/OurWay";
import OurWayWork from "@/components/theHow/OurWayWork";
import OurHolding from "@/components/theHow/OurHolding";
import OurNetwork from "@/components/theHow/OurNetwork";
import useEventBus from "@/composables/useEventBus";
export default {
  name: "TheHow",
  components: {
    LoaderComponent,
    Header,
    Banner,
    labelComponent,
    OurWay,
    OurWayWork,
    OurHolding,
    OurNetwork,
  },

  setup() {
    const { emitEvent } = useEventBus();
    const bannerInfo = ref({})
    const ourWayInfo = ref({})
    const holdingInfo = ref({})
    const labelText = ref('')
    const dataNetwork = ref(null)
    const isLoading = ref(false)
    const errorMessage = ref('')

    const getData = async () => {
      isLoading.value = true
      try {
        const { data } = await axios.get("/pages/97");
        getBannerInfo(data.acf);
        getLabelText(data.acf);
        getOurWayInfo(data.acf);
        getHoldingInfo(data.acf);
        getDataNetwork(data.acf);
        errorMessage.value = null

      } catch (error) {
        errorMessage.value = "No se pudieron cargar los datos"
        isLoading.value = false
      }
      isLoading.value = false

    };
    const getBannerInfo = (dataResponse) => {
      const { img_mobile,
        img_desktop,
        title,
        subtitle,
        description } = dataResponse

      bannerInfo.value = {
        img_mobile,
        img_desktop,
        title,
        subtitle,
        description
      }
    }
    const getLabelText = (dataResponse) => {
      labelText.value = dataResponse.banner
    }
    const getOurWayInfo = (dataResponse) => {
      const { our_way_of_work_title, our_way_of_work_subtitle, banner_our_way_of_work } = dataResponse
      ourWayInfo.value = {
        our_way_of_work_title, our_way_of_work_subtitle, banner_our_way_of_work
      }
    }
    const getHoldingInfo = (dataResponse) => {
      const { our_holding_title, our_holding_subtitle, our_holding_logo, agencies } = dataResponse
      holdingInfo.value = { our_holding_title, our_holding_subtitle, our_holding_logo, agencies }
    }
    const getDataNetwork = (dataResponse) => {
      const { network_title, network_description, network_image } = dataResponse
      dataNetwork.value = { network_title, network_description, network_image }

    }

    getData()

    const handleAnchorScroll = (name) => {
      emitEvent("anchorLink", name || "Empty message..");
    };

    return {
      handleAnchorScroll,
      bannerInfo,
      labelText,
      ourWayInfo,
      holdingInfo,
      dataNetwork,
      isLoading,
      errorMessage
    };
  },
};
</script>
