<template>
  <section class="thePeople" data-section="dark" v-if="membersInfo">
    <div class="container container--general">
      <div class="thePeople__title">
        <h2 class="subtitles subtitles--medium">{{ membersInfo.people_title }}</h2>
        <p>
          {{ membersInfo.people_subtitle }}
        </p>
      </div>
    </div>
    <div class="container container--full">
      <div class="thePeople__carousel">
        <swiper v-if="membersInfo.people && membersInfo.people.length > 0" @slideChange="disabledArrows"
          @swiper="setControlledSwiper" :spaceBetween="32" :slidesPerView="3.8" :centeredSlides="false"
          :mousewheel="false" :speed="600" class="thePeople__carousel__main" :breakpoints="CAROUSEL_PEOPLE.breakpoints">
          <swiper-slide class="thePeople__carousel__box" v-for="(people, index) in membersInfo.people" :key="index">
            <div class="thePeople__card">
              <div class="thePeople__card__thumbnail">
                <img class="image-cover"
                  :src="people.image ? people.image : require('@/assets/images/generals/sancho-placeholder.png')"
                  alt="People photo" />
              </div>
              <div class="thePeople__card__body">
                <div class="thePeople__card__information">
                  <h3>{{ people.name }}</h3>
                  <p>{{ people.position }}</p>
                </div>
                <div class="thePeople__card__socialIn">
                  <a :href="people.linkedin">
                    <img src="@/assets/images/generals/in-bl.svg" alt="" />
                  </a>
                </div>
              </div>
            </div>
          </swiper-slide>

        </swiper>
        <div class="detailTheWork__otherProyects__title__navs">
          <button class="
              detailTheWork__otherProyects__title__navs__arrows
              detailTheWork__otherProyects__title__navs__arrows__prev
            " :class="{
              'detailTheWork__otherProyects__title__navs__arrows__prev--disabled':
                isFirst,
            }" @click="controlledSwiper.slidePrev()" :disabled="isFirst">
            <img src="@/assets/images/generals/prevArrow.svg" alt="" />
          </button>
          <button class="
              detailTheWork__otherProyects__title__navs__arrows
              detailTheWork__otherProyects__title__navs__arrows__next
            " :class="{
              'detailTheWork__otherProyects__title__navs__arrows__next--disabled':
                isEndS,
            }" @click="controlledSwiper.slideNext()" :disabled="isEndS">
            <img src="@/assets/images/generals/nextArrow.svg" alt="" />
          </button>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { ref, onUpdated } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, { Navigation } from "swiper";
import { CAROUSEL_PEOPLE } from "@/helpers/sliders/slidersOptions";
import "swiper/swiper.scss";
import 'swiper/components/navigation/navigation.min.css';

export default {
  name: "ThePeople",
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    membersInfo: ref(null),
  },
  setup() {
    const controlledSwiper = ref(null);
    const isFirst = ref(false);
    const isEndS = ref(false);
    const numSlides = ref(null);

    const setControlledSwiper = (Swiper) => {
      controlledSwiper.value = Swiper;
    };
    onUpdated(() => {
      disabledArrows();
    });

    function disabledArrows() {
      numSlides.value = controlledSwiper.value.el.children[0].children.length;
    
      if (controlledSwiper.value.activeIndex === 0) {
        isFirst.value = true;
        isEndS.value = false;
      } else if (controlledSwiper.value.isEnd === true) {
        isFirst.value = false;
        isEndS.value = true;
      } else {
        isFirst.value = false;
        isEndS.value = false;
      }
    }
    SwiperCore.use([Navigation]);
    return {
      setControlledSwiper,
      controlledSwiper,
      disabledArrows,
      isFirst,
      isEndS,
      CAROUSEL_PEOPLE
    };
  },
};
</script>