<template>
  <section class="featuredBlog" data-section="light">
    <div class="featuredBlog__title container container--general__small">
      <h2>{{ featuredTitle }}</h2>
    </div>
    <div class="featuredBlog__posts">
      <swiper :direction="'horizontal'" :mousewheel="false" :spaceBetween="24" :slidesPerView="'auto'"
        :centeredSlides="false" class="featuredBlog__posts__carousel" :breakpoints="{
          '1025': {
            mousewheel: {
              enabled: true,
              sensitivity: 1,
              releaseOnEdges: true,
              thresholdDelta: 4,
            },
            centeredSlides: true,
            spaceBetween: '48',
          },
        }">
        <swiper-slide v-for="(feat, index) in featuredPosts" :key="index">
          <router-link :to="{ name: 'Detail Blog', params: { slug: feat.slug } }" class="featuredBlog__posts__post">
            <div class="featuredBlog__posts__post__thumbnail">
              <img :src="feat.acf.banner_mobile" :alt="feat.title.rendered" />
            </div>
            <div class="featuredBlog__posts__post__body">
              <h5 class="text-black">
                {{ feat.title.rendered }}
              </h5>
              <img src="@/assets/images/generals/rightArrow.svg" alt="" />
            </div>
          </router-link>
        </swiper-slide>

      </swiper>
    </div>
  </section>
</template>
<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper Styles
import "swiper/swiper.scss";

// import Swiper core and required modules
import SwiperCore, { Mousewheel } from "swiper";
import { ref } from '@vue/reactivity';

// install Swiper modules
SwiperCore.use([Mousewheel]);

export default {
  name: "Featured",
  props: {
    featuredPosts: ref(null),
    featuredTitle: ref(null)
  },
  components: {
    Swiper,
    SwiperSlide,
  },
};
</script>
