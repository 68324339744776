<template>
  <LoaderComponent :loading="isLoading" />
  <span v-if="isLoading"></span>
  <span class="errorSite" v-else-if="!dataResponse || errorMessage"> {{ errorMessage }}</span>
  <template v-else>
    <div class="contactUs">
      <section class="contactUs__container">
        <div class="contactUs__info" data-section="dark">
          <p>{{ dataResponse.message }}</p>
          <h1>Contáctanos</h1>
          <h2>{{ dataResponse.title }}</h2>
          <ul class="contactUs__info__list">
            <li class="contactUs__info__list__item" v-for="(contact, index) in dataResponse.contact" :key="index">
              <h3>{{ contact.title }}</h3>
              <p><a :href="contact.url">{{ contact.content }}</a></p>
            </li>
          </ul>
        </div>
        <div class="contactUs__form">
          <h1>Contáctanos</h1>
          <Form @onModalSent="setModalMessage" />

        </div>
      </section>
    </div>
  </template>

  <ModalInfo :variant="statusForm" ref="modalFormRef" />
</template>
<script>
import { ref } from '@vue/reactivity';
import axios from "axios";
import LoaderComponent from "@/components/globals/LoaderComponent";
import Header from "@/components/globals/Header";
import Form from "@/components/contact/Form";
import ModalInfo from '@/components/globals/ModalInfo';

export default {
  name: "ContactUs",
  components: {
    LoaderComponent,
    Header,
    Form,
    ModalInfo
  },

  setup() {
    const isLoading = ref(true);
    const errorMessage = ref('');
    const dataResponse = ref(null)
    const modalMessage = ref(null)
    const modalFormRef = ref()
    const statusForm = ref('')

    const getData = async () => {
      isLoading.value = true
      try {
        const { data } = await axios.get("/pages/193");
        dataResponse.value = data.acf
        errorMessage.value = null
      } catch (error) {
        console.log(error);
        errorMessage.value = "Ocurrió un error al cargar los datos"
      }
      isLoading.value = false
    };
    getData()

    return {
      dataResponse,
      isLoading,
      errorMessage,
      setModalMessage: (message, status) => {
        modalFormRef.value.toggleModal = true
        modalFormRef.value.infoModal = message
        statusForm.value = status
      },
      modalFormRef,
      statusForm
    }
  },

};
</script>
