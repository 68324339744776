const CAROUSEL_HOW = {
  autoplay: {
    disableOnInteraction: false,
    delay: 500000,
  },
  speed: 700,
  centeredSlides: true,
  navigation: {
    nextEl: ".how-next",
    prevEl: ".how-prev",
  },
  breakpoints: {
    0: {
      slidesPerView: 1,
    },
    768: {
      slidesPerView: 2,
      spaceBetween: 40,
    },
    1024: {
      slidesPerView: 3,
      slidesPerGroup: 1,
      spaceBetween: 100,
    },
    1280: {
      slidesPerView: 4,
      slidesPerGroup: 1,
      spaceBetween: 120,
    },
  },
};
const CAROUSEL_HOME_WORK = {
  speed: 600,
  navigation: {
    nextEl: ".work-next",
    prevEl: ".work-prev",
  },
  spaceBetween: 20,
  breakpoints: {
    0: {
      slidesPerView: 1.5,
    },
    480: {
      slidesPerView: 2,
    },
    768: {
      slidesPerView: 3,
    },
    1025: {
      mousewheel: {
        enabled: true,
        sensitivity: 1,
        releaseOnEdges: true,
        thresholdDelta: null,
        thresholdTime: 500,
      },
      centeredSlides: true,
      spaceBetween: "48",
      slidesPerView: 4,
    },
  },
};

const CAROUSEL_PEOPLE = {
  breakpoints: {
    0: {
      mousewheel: {
        enabled: true,
        sensitivity: 7.5,
        releaseOnEdges: true,
        thresholdDelta: 1,
      },
      slidesPerView: 1.3,
      spaceBetween: 40,
    },
    768: {
      mousewheel: {
        enabled: true,
        sensitivity: 7.5,
        releaseOnEdges: true,
        thresholdDelta: 1,
      },
      slidesPerView: 2.5,
    },
    1025: {
      mousewheel: {
        enabled: true,
        sensitivity: 7.5,
        releaseOnEdges: true,
        thresholdDelta: 1,
      },
      spaceBetween: 100,
    },
  },
};

export { CAROUSEL_HOW, CAROUSEL_HOME_WORK, CAROUSEL_PEOPLE };
