<template>
  <LoaderComponent :loading="isLoading" />
  <div class="errorSite" v-if="!dataBlogdetail || errorMessage">{{ errorMessage }}</div>
  <div v-else class="detailBlog" data-section="light">
    <Banner :bannerInfo="bannerInfo" />

    <div class="container detailBlog__content">
      <CategorieList :categorieList="categorieList" />

      <div v-for="(datas, index) in dataBlogdetail.detail_layout " :key="index">
        <template v-if="datas.blog_content">
          <section class="detailBlog__simpleText" data-section="dark" v-html="datas.blog_content"></section>
        </template>
        <template v-if="datas.unequal_box">
          <ImagesWithSpaces :unequalImages="datas.unequal_box" />
        </template>
        <template v-if="datas.vertical_images">
          <VerticalImages :verticalImages="datas.vertical_images" />
        </template>
        <template v-if="datas.image_media || datas.video_media">
          <FullImage :mediaInfo="{ image: datas.image_media, video: datas.video_media, isVideo: datas.load_video }" />
        </template>
      </div>


    </div>
  </div>
</template>
<script>
import { watch } from 'vue';

import Header from "@/components/globals/Header";
import Banner from "@/components/detailBlog/Banner";
import SimpleText from "@/components/detailBlog/SimpleText";
import ImagesWithSpaces from "@/components/detailBlog/ImagesWithSpaces";
import FullImage from "@/components/detailBlog/FullImage";
import VerticalImages from "@/components/detailBlog/VerticalImages";
import CategorieList from "@/components/globals/Categories";
import LoaderComponent from "@/components/globals/LoaderComponent";

import { useRoute } from 'vue-router';
import useDetailBlog from "../composables/blog/useBlogDetail"

export default {
  name: "DetailBlog",
  components: {
    Header,
    Banner,
    SimpleText,
    ImagesWithSpaces,
    FullImage,
    VerticalImages,
    CategorieList,
    LoaderComponent
  },
  setup() {

    const route = useRoute()
    watch(
      () => route.params.slug,
      () => getBlogDetail(route.params.slug)
    )
    const { isLoading, bannerInfo, errorMessage, getBlogDetail, dataBlogdetail, categorieList } = useDetailBlog(route.params.slug)
    return {
      bannerInfo,
      errorMessage,
      dataBlogdetail,
      categorieList,
      isLoading
    };
  },
};
</script>