<template>
  <LoaderComponent :loading="isLoading" />
  <div class="errorSite" v-if="!vacancieDetail || errorMessage">{{ errorMessage }}</div>
  <template v-else>
    <div class="detailThatIs" data-scroller>
      <InfoVacancie :infoVacancie="vacancieDetail" />
      <section class="detailThatIs__form" data-section="dark">
        <div class="detailThatIs__form__title">
          <h2>Aplica ya</h2>
        </div>
        <div class="detailThatIs__form__container">
          <Form />
        </div>
      </section>
    </div>
  </template>
</template>
<script>

import { ref, watch } from 'vue'

import Header from "@/components/globals/Header";
import InfoVacancie from "@/components/detailThatIsHowItWorks/InfoVacancie";
import Form from "@/components/globals/Form.vue";
import { useRoute } from 'vue-router';
import axios from 'axios';
import LoaderComponent from "@/components/globals/LoaderComponent";

export default {
  name: "DetailThatIsHowItWorks",
  components: {
    LoaderComponent,
    Header,
    InfoVacancie,
    Form,
  },
  setup() {
    const route = useRoute()
    const vacancieDetail = ref('')
    const isLoading = ref(false)
    const errorMessage = ref('')

    const getVacancieDetail = async (slug) => {
      if (!slug) return
      isLoading.value = true;
      try {
        const { data } = await axios.get(`/jobs?slug=${slug}`);
        const [response] = data
        vacancieDetail.value = response.acf
        errorMessage.value = null;
        scrollTop()
      } catch (error) {
        isLoading.value = false;
        errorMessage.value = "No se pudieron cargar los datos";
      }
      isLoading.value = false
    };



    watch(
      () => route.params.slug,
      () => getVacancieDetail(route.params.slug))

    getVacancieDetail(route.params.slug)

    const scrollTop = () => {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    }
    return {
      vacancieDetail,
      isLoading,
      errorMessage
    };
  },
};
</script>