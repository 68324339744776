const isMobile = window.innerWidth <= 1024;
export const handleScrollHorizontal = () => {
  const horizontalScroll = document.querySelector(".horizontalScroll");
  if (horizontalScroll && !isMobile) {
    horizontalScroll.addEventListener(
      "wheel",
      (e) => {
        e.preventDefault();
        horizontalScroll.scrollLeft += e.deltaY;
      },
      { passive: false }
    );
  }
};
