<template >
    <div @dragenter.prevent="setActive" @dragleave.prevent="setInactive" @dragover.prevent="setActive"
        @drop.prevent="dropFile" :class="{ 'active': active }" class="form__dropzone">
        <img src="@/assets/images/generals/upload.svg" alt="" />
        <p>{{ inputCopy }}</p>
        <span>{{ dropzoneFile.name }}</span>
        <input class="form__file" type="file" @change="dropFile" name="fileUpload" accept=".pdf">
        <span class="form__caption">Elige unicamente archivos en formato PDF.</span>
    </div>
</template>
<script>
import { ref } from 'vue';

export default {
    name: 'InputFile',
    props: {
        inputCopy: {
            type: String,
            default: 'arrastra y suelta tu archivo'
        },
        modelValue: null
    },
    setup(_, context) {
        let dropzoneFile = ref("");
        const active = ref(false);

        const dropFile = async (e) => {
            const fileUploaded = e.dataTransfer?.files[0] || e.target?.files[0]
            console.log("dropZone", fileUploaded);
            // validate pdf type 
            if (fileUploaded.type !== 'application/pdf') {
                setInactive()
                return
            };

            dropzoneFile.value = fileUploaded

            setInactive()
            context.emit('update:modelValue', fileUploaded);
        };
        const setActive = () => {
            active.value = true;
            console.log('setActive')
        };
        const setInactive = () => {
            active.value = false;
            console.log("setInactive");
        };

        return {
            dropzoneFile,
            active,
            setActive,
            setInactive,
            dropFile
        }
    }
}
</script>
