<template>
  <section class="miniCarousel">
    <div class="miniCarousel__container">
      <p class="ttU label-animation" v-for="(i, index) in 10" :key="index">
        {{ labelText }}
      </p>
    </div>
  </section>
</template>
<script>

import { ref } from "vue";
export default {
  name: "labelComponent",
  props: {
    labelText: ref(null),
  },

};
</script>