<template>
  <section class="ourWay" data-section="dark">
    <div class="container container--general">
      <div class="ourWay__grid">
        <div v-if="result" class="ourWay__left">
          <h2 class="subtitles subtitles--medium">{{ result.our_way_of_work_title }}</h2>
          <p>{{ result.our_way_of_work_subtitle }}</p>
        </div>
        <div v-if="result" class="ourWay__right">

          <div class="ourWay__right__box" v-for="(way, index) in result.banner_our_way_of_work" :key="index">
            <a @click="scrollSection(`section-${index}`)">
              <h3>{{ way.title }}</h3>
              <img src="@/assets/images/generals/rightArrow.svg" alt="arrow right" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { ref } from "vue";
export default {
  name: "OurWay",
  props: {
    result: ref(null),
    scrollSection: Function
  },
};
</script>