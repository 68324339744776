<template>
  <section class="detailBlog__simpleText" data-section="dark">
    <p>
      Aliquam dolor porttitor id posuere est tortor. Diam eu enim id sit aenean
      facilisis. Tincidunt quam ultrices magna mauris, cursus justo, nibh.
      Tortor enim nisl, aliquam parturient magnis. Sit pharetra at facilisi eget
      fusce vitae aliquet velit nisi.
    </p>
    <p>
      Porta justo, amet libero ultrices in amet arcu volutpat proin. Cursus
      habitant est accumsan, scelerisque dictum. Nam id diam dui lacus aliquam.
      In nunc pulvinar consectetur semper augue. Pellentesque suspendisse
      suspendisse a luctus viverra. Consequat lectus mi eget cursus velit nec,
      porttitor eget. Nisl vel placerat mauris nisi odio gravida magnis
      porttitor. Tempus in fames vestibulum id at auctor quisque morbi id. Leo
      vestibulum fames purus, feugiat ultricies viverra.
    </p>
    <p>
      Hac dui ipsum urna dui elit congue id vitae. Nulla in sit hendrerit nunc
      fames orci. Blandit at nulla donec aliquet nec potenti feugiat parturient
      mollis. Amet ullamcorper purus non interdum feugiat volutpat pharetra at
      massa. Vitae neque iaculis pellentesque amet elementum malesuada morbi.
      Montes, id quisque ut diam, amet, facilisis viverra accumsan. Malesuada
      quis integer velit fermentum mattis. Lacus sem orci, nunc eu aliquam. Non
      hendrerit quam elit facilisi purus, semper magna.
    </p>
  </section>
</template>

<script>
export default {
    name:"SimpleText"
}
</script>