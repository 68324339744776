<template>
  <section class="projects" data-section="light">
    <div class="container container--general__small">
      <div class="projects__top">
        <h2 class="ttU subtitles subtitles--lSmall text-white">
          filtrar proyectos
        </h2>
        <div class="selectBox">
          <select name="orderBY" @change="sortFilter($event)">
            <option class="ttU" value="" selected>Ordenar Por</option>
            <option class="ttU" value="asc">proyectos recientes</option>
            <option class="ttU" value="desc">proyectos antiguos</option>
          </select>
          <img src="@/assets/images/generals/arrowDownWh.svg" alt="select icon" />
        </div>
      </div>
      <!-- desktop categories  -->
      <div class="filterBox" v-if="categories && categories.length > 0">
        <ul class="filterBox__list">
          <li v-for="category in categories" :key="category.id" class="filterBox__item" :class="{
            'active':
            category.isSelected,
          }" :data-category="category.slug" @click="selectCategory(category)">
            {{ category.name }}
          </li>
        </ul>
      </div>
      <!-- desktop categories  -->
      <!-- projects cards  -->
      <div class="projects__grid">
        <template v-if="filteredProjects && filteredProjects.length>0">
          <router-link :to="{
            name: 'Detail The Work',
            params: { slug: proyect.slug },
          }" class="projects__card" v-for="proyect in displayProjects" :key="proyect.id" data-scrub="0.2">
            <div class="projects__thumbnail">
              <img
                :src="proyect.acf.banner_thumbnail?proyect.acf.banner_thumbnail:require('@/assets/images/generals/sancho-placeholder.png')"
                :alt="proyect.title.rendered " class="image-cover" />
            </div>
            <div class="projects__body">
              <div class="projects__description">
                <h3>{{ proyect.title.rendered }}</h3>
                <p>{{ proyect.acf.client }}</p>
              </div>
              <div class="projects__body__action">
                <img src="@/assets/images/generals/leftArrowWh.svg" alt="" />
              </div>
            </div>
          </router-link>
        </template>
        <div class="text-white" v-else>No hay resultados</div>
      </div>
      <!-- projects cards  -->

      <div class="projects__pagination" v-if="filteredProjects.length >=itemsPerPage">
        <vue-awesome-paginate :total-items="filteredProjects.length" :items-per-page="itemsPerPage" :max-pages-shown="5"
          :current-page="1" :on-click="paginationClick">
          <template #prev-button>
            <img src="@/assets/images/generals/previous.svg" class="image-contain" alt="" />
          </template>
          <template #next-button>
            <img src="@/assets/images/generals/next.svg" class="image-contain" alt="" />
          </template>
        </vue-awesome-paginate>

      </div>
    </div>
  </section>
  <section class="theWorkView__categoriesMob" @click="displayCategoriesModal">
    <div class="theWorkView__categoriesMob__container">
      <img src="@/assets/images/generals/filter.svg" alt="" />
      <p>filtrar proyectos</p>
    </div>
  </section>
  <Categories :showModal="isShow" :categories="categories" :aplFilter="selectCategory" />
</template>
<script>


import Categories from "@/components/theWork/Categories.vue";

import { ref, onMounted, onUpdated, toRefs, computed } from "vue";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import axios from "axios";

gsap.registerPlugin(ScrollTrigger);
export default {
  name: "TheProyects",
  props: ['projects'],
  components: {
    Categories,
  },
  setup(props) {
    const categories = ref([]);
    const cats = ref([]);
    const currentPage = ref(1)
    const itemsPerPage = ref(8)
    const isShow = ref(false);
    const filteredProjects = ref(null);
    const { projects } = toRefs(props)

    filteredProjects.value = projects.value;

    const displayProjects = computed(() => {
      return handlePagination(filteredProjects.value)
    })

    const handlePagination = (posts) => {
      let page = currentPage.value;
      let perPage = itemsPerPage.value;
      let from = (page * perPage) - perPage;
      let to = (page * perPage);
      return posts.slice(from, to);
    }

    const paginationClick = (page) => {
      currentPage.value = page
    };

    onMounted(async () => {
      cats.value = await getCategories();
      const dataCats = await JSON.parse(JSON.stringify(cats.value.data));
      categories.value = dataCats;
      categories.value.map((category) => {
        category.isSelected =
          category.slug === "todos"
            ? (category.isSelected = true)
            : (category.isSelected = false);
      });

    });
    onUpdated(() => {
      const boxes = document.querySelectorAll(
        ".projects__card"
      );
      boxes.forEach((box) => {
        const boxAnim = gsap.to(
          box.querySelector(".projects__thumbnail"),
          {
            y: "100px",
            ease: "none",
          }
        );
        const imageAnim = gsap.to(
          box.querySelector(
            ".image-cover"
          ),
          {
            y: "-104px",
            ease: "none",
            paused: true,
          }
        );
        const scrub = gsap.to(imageAnim, {
          progress: 1,
          paused: true,
          ease: "power3",
          duration: parseFloat(box.dataset.scrub) || 0.2,
          overwrite: true,
        });
        ScrollTrigger.create({
          animation: boxAnim,
          scrub: true,
          trigger: box,
          start: "top bottom",
          end: "bottom top",
          onUpdate: (self) => {
            scrub.vars.progress = self.progress;
            scrub.invalidate().restart();
          },
        });
      });
    });

    const selectCategory = (categoryData) => {
      categories.value.map(cat => cat.isSelected = false)
      categoryData.isSelected = true;

      filteredProjects.value = props.projects.filter(item => {
        if (item.categories.length > 0 && item.categories.includes(categoryData.id)) {
          return item
        }
        if (categoryData.name === 'Todos') {
          return item
        }
      })
      if (isShow.value) {
        isShow.value = !isShow.value
      }
    };


    const sortFilter = (e) => {
      const optionSelected = e.target.value;
      proyectsSortFilter(optionSelected);
    };
    const proyectsSortFilter = (optionSelected) => {
      let sorted = filteredProjects.value.sort((a, b) => {
        if (optionSelected === "desc") {
          return new Date(a.date) - new Date(b.date);
        } else {
          return new Date(b.date) - new Date(a.date);
        }
      });
      filteredProjects.value = sorted;

    };
    const displayCategoriesModal = () => {
      isShow.value = !isShow.value;
    };

    const getCategories = async () => {
      return await axios.get("/categories");
    };

    return {
      categories,
      filteredProjects,
      selectCategory,
      proyectsSortFilter,
      sortFilter,
      displayCategoriesModal,
      isShow,
      paginationClick,
      itemsPerPage,
      displayProjects,
      handlePagination,
      currentPage
    };
  },
};
</script>
