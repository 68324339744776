<script>
import axios from "axios";
import { ref } from '@vue/reactivity';
import { useRouter } from "vue-router";
export default {
  name: "Footer",
  setup() {
    const router = useRouter();
    const result = ref(null);
    const headerContent = ref(null);
    const currentRoute = ref(null);

    const getFooterData = async () => {
      try {
        const { data: dataResult } = await axios.get("/pages/184");
        const { data: dataHeader } = await axios.get("/pages/189");
        result.value = dataResult.acf;
        headerContent.value = dataHeader.acf;
      } catch (error) {
        console.log(error);
        result.value = null;
        headerContent.value = null;
      }
    };

    getFooterData()

    // watch router change and set currentRoute
    router.afterEach((to, from) => {
      currentRoute.value = to.name;
    });


    return {
      result,
      headerContent,
      currentRoute
    }
  }
};
</script>

<template>
  <div v-if="result" class="container">
    <div class="container container--general">
      <div class="footer">
        <div class="footer__main">
          <div class="footer__main__left" v-if="currentRoute !== 'Contact Us'">
            <h3>
              {{ result.footer_title }}
            </h3>
            <p>
              {{ result.footer_description }}
            </p>
            <router-link class="btn btn--black" v-if="result.contact_us_button.button_contact_link"
              :to="result.contact_us_button.button_contact_link">
              {{ result.contact_us_button.button_name }}
            </router-link>

          </div>
          <div v-if="headerContent" class="footer__social">
            <template v-for="(social,) in headerContent.social_networks" :key="index">
              <a v-if="social.url && social.logo_black" class="footer__social__item" :href="social.url" target="_blank">
                <img :src="social.logo_black" alt="social logo" class="image-contain" />
              </a>
            </template>

          </div>
        </div>
        <div v-if="headerContent" class="footer__logo">
          <img :src="headerContent.logo_black" alt="logo footer" />
        </div>
        <div class="footer__bottom" v-if="headerContent">
          <ul class="footer__bottom__menu hidden">
            <li class="footer__bottom__menu__item" v-for="(menuItem, index) in headerContent.page" :key="index">
              <router-link :to="menuItem.page_path">
                {{ menuItem.page_name }}
              </router-link>
            </li>

          </ul>
          <ul class="footer__bottom__politics">
            <li class="footer__bottom__politics__item" v-if="result.policies_group.terms_file">
              <a :href="result.policies_group.terms_link" target="_blank">
                {{ result.policies_group.terms_text }}
              </a>
            </li>
            <li class="footer__bottom__politics__item" v-if="result.policies_group.policies_file">
              <a :href="result.policies_group.policies_file" target="_blank">
                {{ result.policies_group.policies_text }}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
