<template>
  <section v-if="ourTeamInfo" ref="ourTeam" class="ourTeam" data-section="dark">
    <div class="container container--general">
      <h2 v-if="ourTeamInfo" class="subtitles subtitles--medium">
        {{ ourTeamInfo.our_team_title }}
      </h2>
      <div class="ourTeam__grid">

        <div class="ourTeam__box" v-for="(infoTeam, index) in ourTeamInfo.our_team_quantity" :key="index">
          <h3>
            {{ infoTeam.quantity_team }}
          </h3>
          <p>{{ infoTeam.team_text }}</p>
          <span v-if="infoTeam.team_description">{{ infoTeam.team_description }}</span>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { ref, onMounted, onUnmounted } from "vue";

export default {
  name: "OurTeam",
  setup() {
    const counters = ref([]);
    const count = ref(Number);
    const ourTeam = ref(null);
    const flag = ref(0);

    onMounted(() => {
      window.addEventListener("scroll", counterScroll);
    });
    onUnmounted(() => {
      window.addEventListener("scroll", counterScroll);
    });

    const counterScroll = (e) => {
      const scrollDistance = window.innerHeight;
      const topOurTeam = ourTeam.value.getBoundingClientRect().top;
      if (flag.value == 0 && topOurTeam < scrollDistance - 150) {
        const counts = setInterval(updated);
        count.value = 0;
        function updated() {
          counters.value.forEach((counter) => {
            const limit = +counter.dataset.counter;
            counter.innerHTML = ++count.value;
            if (count.value === limit) {
              clearInterval(counts);
            }
          });
        }
        flag.value = 1;
      }
    };

    return {
      counters,
      count,
      counterScroll,
      ourTeam,
    };
  },
  props: {
    ourTeamInfo: ref(null),
  }
};
</script>