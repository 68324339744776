<template>
    <div class="imageMedia" data-section="dark">
        <img v-if="!mediaInfo.load_video" :src="mediaInfo.image" alt="blog image" class="image-cover">
        <video v-else autoplay muted loop playsinline
            src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4"></video>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity'
export default {
    name: "FullImage",
    props: {
        mediaInfo: ref(null)
    }
}
</script>